<template>
<nav class="hidden md:flex mb-4 h-8" aria-label="Breadcrumb">
  <ol role="list" class="flex space-x-4 rounded-md bg-white px-6 shadow">
  <li class="flex">
      <div class="flex items-center">
      <router-link to="/hub" class="transition ease-in-out duration-300 text-gray-400 hover:text-gray-500">
          <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
          <span class="sr-only">Home</span>
      </router-link>
      </div>
  </li>
  <li class="flex">
      <div class="flex items-center">
      <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
          <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
      </svg>
      <router-link to="/dashboard/storage" class="transition ease-in-out duration-300 ml-4 text-xs font-medium text-gray-500 hover:text-gray-700" aria-current="page">Moduł magazyn</router-link>
      </div>
  </li>
  <li class="flex">
      <div class="flex items-center">
      <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
          <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
      </svg>
      <div class="ml-4 text-xs font-medium text-gray-400 cursor-default" aria-current="page">Skaner kodów QR</div>
      </div>
  </li>
  </ol>
</nav>
  <router-link to="/dashboard/storage" class="block md:hidden transition ease-in-out duration-300 mb-2 text-sm font-medium text-blue-500 hover:text-blue-700" aria-current="page">
    &larr; Cofnij do ekranu początkowego
  </router-link>

  <Transition name="fade">
    <div v-if="blockModal.show" class="h-full w-full absolute z-50 flex justify-center items-center">
      <div class="bg-white w-64 p-4 rounded-lg shadow-xl flex justify-center items-center flex-col">
        <div class="mx-auto mb-2 flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-yellow-100 sm:mx-0 sm:h-11 sm:w-11">
          <ExclamationIcon class="h-8 w-8 text-yellow-600" aria-hidden="true" />
        </div>
        <div class="font-medium text-gray-700">Trasa została zaktualizowana</div>
        <div v-if="blockModal.updatedBy.length > 0" class="text-gray-500 text-sm">przez: {{blockModal.updatedBy}}</div>
        <button @click="refreshData()" :class="['transition-all mt-3 duration-300 bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 rounded-md border border-indigo-300 py-2 px-4 text-sm font-medium text-white shadow-sm flex gap-1 items-center']"><RefreshIcon class="h-4 w-4 text-white " aria-hidden="true" />Odśwież</button>
      </div>
    </div>
  </Transition>
  <div class="h-full w-full" :class="blockModal.show ? 'pointer-events-none opacity-30' : ''">
    <div class="min-h-full mt-8 overflow-visible" v-if="step === 1">
      <div class="mx-auto max-w-7xl px-0.5 pr-1">
        <h1 class="sr-only">Page title</h1>
        <!-- Main 3 column grid -->
        <div class="grid grid-cols-1 items-start gap-4 lg:grid-cols-3 lg:gap-8">
          <LoadingSpinnerHub v-if="loading" />
          <Transition name="fade">
          <div class="grid grid-cols-1 gap-4 lg:col-span-3" v-if="routes.length > 0 && !loading">
            <section aria-labelledby="section-1-title">
              <h2 class="sr-only" id="section-1-title">Section title</h2>
              <div :class="[routes.length > 0 ? 'bg-white' : 'bg-gray-100 shadow-none', 'rounded-lg shadow p-6']">
                <Listbox as="div" v-model="selected">
                  <ListboxLabel class="block text-sm font-medium leading-6 text-gray-900">Wybór trasy</ListboxLabel>
                  <div class="relative mt-2">
                    <ListboxButton class=" relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                      <span :class="[selected.length > 0 ? 'text-black' : 'text-gray-500', 'block truncate']">{{ selected.length > 0 ? selected : 'Wybierz trasę...' }}</span>
                      <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                      </span>
                    </ListboxButton>
                    <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                      <ListboxOptions class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        <ListboxOption @click="routeSelector(route.id)" as="template" v-for="route in routes" :key="route.id" :value="route.name" v-slot="{ active, selected }">
                          <li :class="[active ? 'bg-indigo-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                              <label for="name" :class="[active ? 'text-white' : 'text-gray-900', 'block text-xs font-medium']">{{route.currentDate}}</label>
                              <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{route.name}}</span>
                              <span v-if="selected" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                <CheckIcon class="h-6 w-6" aria-hidden="true" />
                              </span>
                          </li>
                        </ListboxOption>
                      </ListboxOptions>
                    </transition>
                  </div>
                </Listbox>
                <EmptyState v-if="routes.length === 0" />
              </div>
            </section>
            <button @click.prevent="changeStep(2, route.id)" :disabled="selected.length === 0" type="button" :class="[selected.length === 0 ? 'opacity-50 cursor-not-allowed' : '', 'transition-all duration-300 inline-flex justify-center items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600']">
              <CheckCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
              Przejdź do skanowania
            </button>
          </div>
          </Transition>
        </div>
      </div>
    </div>
    <div v-if="step === 2" class="flex flex-col">
      <LoadingSpinnerHub class="select-none" v-if="loading" />
      <div v-if="!loading" class="pr-1">
        <button @click.prevent="changeStep(1)" id="previousStep" :disabled="selected.length === 0" type="button" :class="[selected.length === 0 ? 'opacity-50 cursor-not-allowed' : '', 'transition-all duration-300 mt-3 mr-1 w-full inline-flex justify-center items-center gap-x-2 rounded-md bg-blue-500 bg-opacity-80 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600']">
          <ArrowCircleLeftIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
          Wybierz inną trasę
        </button>
        <div class="pt-4 flex flex-col gap-4">
          <div v-if="allPackagesExists" id="container" class="border w-full" style=""></div>
          <div class="text-center w-full text-sm uppercase font-semibold">{{showAllOrders ? 'Ilość zeskanowanych paczek' : 'Bieżący etap załadunku'}}</div>
          <div v-auto-animate :class="showAllOrders ? 'justify-center' : 'justify-between'" class="flex items-center select-none">
            <button v-if="!showAllOrders" type="button" @click="changeStage('left')" :disabled="showAllOrders || (currentOrderIndex === 0 && currentPackageIndex === 0)" :class="[showAllOrders || (currentOrderIndex === 0 && currentPackageIndex === 0) ? 'bg-gray-200 cursor-not-allowed text-gray-500' : 'bg-indigo-200 text-indigo-600 hover:bg-indigo-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2']" class="disableZoom justify-center w-fit whitespace-nowrap transition-all duration-300 rounded-md  text-xs font-semibold  shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 inline-flex items-center px-2.5 py-1.5  select-none">
              <ArrowLeftIcon class="-ml-0.5 h-5 w-5 mr-1" aria-hidden="true" />
              <span>Poprzedni etap</span>
            </button>
            <div class="text-sm font-medium"><span :class="showAllOrders ? 'text-green-500' : route.orders[currentOrderIndex].ref.packages[currentPackageIndex].scannedInStorage ? 'text-green-500' : 'text-red-500'" >{{showAllOrders ? countScannedPackages() : (currentStage)}}</span>/{{countPackages()}}</div>
            <button v-if="!showAllOrders" type="button" @click="changeStage('right')" :disabled="showAllOrders || (currentOrderIndex === route.orders.length-1 && currentPackageIndex === route.orders[currentOrderIndex].ref.packages.length-1)" :class="[showAllOrders || (currentOrderIndex === route.orders.length-1 && currentPackageIndex === route.orders[currentOrderIndex].ref.packages.length-1) ? 'bg-gray-200 cursor-not-allowed text-gray-500' : 'bg-indigo-200 text-indigo-600 hover:bg-indigo-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2']" class="disableZoom justify-center w-fit whitespace-nowrap transition-all duration-300 rounded-md  text-xs font-semibold  shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 inline-flex items-center px-2.5 py-1.5  select-none">
              <span>Następny etap</span>
              <ArrowRightIcon class="ml-1 h-5 w-5 -mr-0.5" aria-hidden="true" />
            </button>
          </div>
          <div v-if="!showAllOrders" class="flex w-full flex-col gap-1 justify-center items-center">
            <div class="text-sm text-indigo-500 font-bold uppercase">{{currentProduct()}}</div>
            <div class="text-sm font-semibold">{{route.orders[currentOrderIndex].ref.packages[currentPackageIndex].id}}</div>
            <button v-auto-animate type="button" @click="packageId = route.orders[currentOrderIndex].ref.packages[currentPackageIndex].id,packageData = route.orders[currentOrderIndex].ref.packages[currentPackageIndex], changeStep(!route.orders[currentOrderIndex].ref.packages[currentPackageIndex].subtracted && !variantIdNotExists ? 4 : 3)" :disabled="route.orders[currentOrderIndex].ref.packages[currentPackageIndex].scannedInStorage" :class="[route.orders[currentOrderIndex].ref.packages[currentPackageIndex].scannedInStorage ? 'bg-green-200 cursor-not-allowed' : 'bg-yellow-100 hover:bg-yellow-200 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2']" class="w-full disableZoom justify-center whitespace-nowrap transition-all duration-300 rounded-md  text-xs font-semibold  shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 inline-flex items-center px-2.5 py-3  select-none">
            <!-- <button v-auto-animate type="button" @click="packageId = route.orders[currentOrderIndex].ref.packages[currentPackageIndex].id,packageData = route.orders[currentOrderIndex].ref.packages[currentPackageIndex], changeStep(3)" :disabled="route.orders[currentOrderIndex].ref.packages[currentPackageIndex].scannedInStorage" :class="[route.orders[currentOrderIndex].ref.packages[currentPackageIndex].scannedInStorage ? 'bg-green-200 cursor-not-allowed' : 'bg-yellow-100 hover:bg-yellow-200 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2']" class="w-full disableZoom justify-center whitespace-nowrap transition-all duration-300 rounded-md  text-xs font-semibold  shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 inline-flex items-center px-2.5 py-3  select-none"> -->
              <div class="flex items-center text-yellow-600" v-if="!route.orders[currentOrderIndex].ref.packages[currentPackageIndex].scannedInStorage">
                <span>
                  <QrcodeIcon class="-ml-0.5 h-6 w-6 mr-1 transform transition-all duration-300" aria-hidden="true" />
                </span>
                <div>{{!route.orders[currentOrderIndex].ref.packages[currentPackageIndex].subtracted && !variantIdNotExists ? 'Wydaj paczkę z magazynu' : 'Skanuj paczkę'}}</div>
                <!-- <div>{{'Skanuj paczkę'}}</div> -->
              </div>
              <div class="flex items-center text-green-600" v-if="route.orders[currentOrderIndex].ref.packages[currentPackageIndex].scannedInStorage">
                <span>
                  <CheckIcon class="-ml-0.5 h-6 w-6 mr-1 transform transition-all duration-300" aria-hidden="true" />
                </span>
                <div>Paczka została zeskanowana</div>
              </div>
            </button>
          </div>
          <div v-if="allPackagesExists" class="flex w-full justify-center">
            <button v-auto-animate type="button" @click="showAllOrders = !showAllOrders, setCurrentPackages(), createPackages()" :class="[false ? 'bg-gray-200 cursor-not-allowed text-gray-500' : 'bg-indigo-200 text-indigo-600 hover:bg-indigo-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2']" class="w-full disableZoom justify-center whitespace-nowrap transition-all duration-300 rounded-md  text-xs font-semibold  shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 inline-flex items-center px-2.5 py-1.5  select-none">
              <span>
                <ChevronDownIcon class="-ml-0.5 h-5 w-5 mr-1 transform transition-all duration-300" :class="!showAllOrders ? '' : 'rotate-180'" aria-hidden="true" />
              </span>
              <div v-if="!showAllOrders">Pokaż wszystkie zamówienia</div>
              <div v-if="showAllOrders">Wróć do widoku etapów</div>
            </button>
          </div>
          <ul v-if="!loading" role="list" :class="showAllOrders ? 'lg:grid-cols-3' : ''" class="grid grid-cols-1 gap-x-6 gap-y-4">
            <li v-for="(order, index) in currentOrder(route.orders)" :key="index" class="overflow-hidden rounded-xl border border-gray-200">
              <div :class="[(order.ref.return != null && order.ref.return) ? 'bg-yellow-400 pointer-events-none' : (order.ref.statusId === 7 || order.ref.statusId === 5 || order.ref.statusId === 6) ? 'hover:bg-green-300 opacity-30 pointer-events-none' : 'hover:bg-indigo-400 hover:text-white', 'flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-300 p-6 cursor-pointer transition-all duration-300']" @click="(order.ref.return != null && order.ref.return) ? '' : order.ref.packages !== undefined && order.ref.packages !== null ? openAdditionalInformation(index, false) : withoutPackage(index, order.ref.id)">
              <div class="flex justify-between w-full items-center">
                <div class="text-sm font-medium leading-6 flex flex-col">
                  <span>{{ order.ref.id }}</span>
                  <span class="text-xs">{{ order.ref.client.name }}</span>
                </div>
                <div class="flex gap-2">
                  <div class="h-6 items-center hover:text-gray-300 outline-none" v-tooltip="`Wyświetl paczki.`" v-if="(order.ref.packages !== undefined && order.ref.packages !== null) && order.ref.statusId === 4">
                    <EyeIcon class="h-6 w-6" v-if="!this.toggleInformation[index].open" />
                    <EyeOffIcon class="h-6 w-6" v-if="this.toggleInformation[index].open" />
                  </div>
                  <span class="font-bold text-yellow-600" v-if="order.ref.return != null && order.ref.return">ZWROT</span>
                  <div v-if="order.ref.return == null || !order.ref.return">
                    <div class="h-6 items-center" v-if="(order.ref.packages !== undefined || order.ref.packages !== null) && (order.ref.statusId === 7 || order.ref.statusId === 5 || order.ref.statusId === 6 || order.ref.statusId === 300)">
                      <div class="flex gap-2">
                        <CheckIcon class="h-6 w-6" v-tooltip="`Wszystkie paczki zostały zeskanowane.`" />
                      </div>
                    </div>
                    <!-- <div @click.prevent="fixPackage(order)">NAPRAW</div> -->
                    <RefreshIcon v-tooltip="'Odśwież zamówienie.'" v-if="(order.ref.statusId !== 7 && order.ref.statusId !== 5 && order.ref.statusId !== 6 && order.ref.statusId !== 300 && order.ref.packages !== undefined && order.ref.packages !== null)" @click.prevent="checkOrders(index, order.ref)" class="h-6 w-6 hover:text-gray-300 outline-none" />
                  </div>
                    <!-- <div @click.prevent="test(order)">NAPRAW 1</div> -->
                </div>
              </div>
              </div>
              <dl v-if="this.toggleInformation[index].open" class="divide-y divide-gray-100 bg-gray-50 cursor-pointer text-sm leading-6" v-auto-animate>
                <div v-for="(pack, index) in order.ref.packages" :key="index" @click.prevent="!pack.scannedInStorage ? withoutPackage(index, pack.id, pack) : ''" :class="[pack.scannedInStorage ? '' : allPackagesExists && route.orders[currentOrderIndex].ref.packages[currentPackageIndex].id === pack.id && !showAllOrders ? 'hover:bg-yellow-200' : 'hover:bg-indigo-500', allPackagesExists && route.orders[currentOrderIndex].ref.packages[currentPackageIndex].id === pack.id && !showAllOrders ? 'bg-yellow-100' : '', 'tranistion-all duration-300 px-6 py-3']">
                  <div class="select-none flex w-full items-center justify-between space-x-6 py-6 tranistion-all duration-150">
                    <div class="flex-1 truncate" >
                      <div class="flex items-center space-x-3">
                        <h3 class="truncate text-sm font-medium">Paczka {{ index+1 }}</h3>
                      </div>
                      <p class="mt-1 truncate text-sm">{{ pack.id }}</p>
                    </div>
                    <span :class="[pack.scannedInStorage ? 'bg-green-50 ring-green-600 ring-opacity-20 text-green-700' : 'bg-red-50 ring-red-600 ring-opacity-20 text-red-700', 'inline-flex flex-shrink-0 items-center rounded-full px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset ']">
                    {{ !pack.subtracted && !pack.scannedInStorage && !variantIdNotExists ? 'Nie odjęto z magazynu' : pack.scannedInStorage ? 'Zeskanowana' : 'Niezeskanowana' }}
                    </span>
                    <!-- <span :class="[pack.scannedInStorage ? 'bg-green-50 ring-green-600 ring-opacity-20 text-green-700' : 'bg-red-50 ring-red-600 ring-opacity-20 text-red-700', 'inline-flex flex-shrink-0 items-center rounded-full px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset ']">
                    {{ pack.scannedInStorage ? 'Zeskanowana' : 'Niezeskanowana' }}
                    </span> -->
                  </div>
                </div>
                <button v-if="this.orderChecked[index].show" @click.prevent="fixOrderPackages(order.ref)" type="button" class="transition-all duration-300 w-full justify-center inline-flex  gap-x-2 rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                  <SwitchVerticalIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                  Zmiana statusu zamówienia.
                </button>
              </dl>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div v-if="step === 3">

      <!-- camera error alert. -->
      <div v-if="error" class="border-l-4 border-red-400 bg-red-50 p-4 mb-4">
        <div class="flex">
          <div class="flex items-center">
            <ExclamationIcon class="h-6 w-6 text-red-400" aria-hidden="true" />
          </div>
          <div class="ml-3">
            <p class="text-sm text-red-700">
              ERROR:
              {{ ' ' }}
              <a href="#" class="font-medium text-red-700 underline hover:text-red-600">{{errorLog}}</a>
            </p>
          </div>
        </div>
      </div>

      <div v-if="!fullscreen">

        <div class="my-6 flex flex-col gap-2">
          <div v-if="!error" class="flex items-center text-gray-600">
            <QrcodeIcon class="w-8 h-8" />
            <div>
              <h4 class="text-sm font-semibold uppercase">Identyfikator zamówienia</h4>
              <p class="-mt-2 text-md">{{ currentPackageId(packageId) }}</p>
            </div>
          </div>
            <div class="relative">
              <div class="absolute inset-0 flex items-center" aria-hidden="true">
                <div class="w-full border-t border-gray-300" />
              </div>
            </div>
          <div v-if="!error" class="flex items-center text-gray-600">
            <TruckIcon class="w-8 h-8" />
            <div>
              <h4 class="text-sm font-semibold uppercase">Paczka</h4>
              <p class="-mt-2 text-md">{{ createUniquePartName(packageId) }}</p>
            </div>
          </div>
        </div>
        <!-- <input placeholder="test" v-model="testScannerId" @change="test"  /> -->
        <div v-if="!fullscreen" class="flex flex-col justify-center items-center gap-4 my-6">
          <span class="uppercase text-gray-500 font-bold text-2xl select-none text-center">Wybierz tryb do skanowania</span>
          <div class="flex gap-12">
            <div @click="fullscreen = false" class="flex flex-col items-center group text-gray-400">
              <QrcodeIcon :class="[fullscreen != null ? !fullscreen ? 'text-indigo-600' : '' : '', 'w-10 h-10 group-hover:text-gray-600 transition-all duration-300']" />
              <span :class="[fullscreen != null ? !fullscreen ? 'text-indigo-600' : '' : '', 'font-semibold text-lg select-none uppercase group-hover:text-gray-600 transition-all duration-300']">Skaner</span>
            </div>
            <div @click="error ? '' : fullscreen = true" :class="[error ? 'opacity-30 pointer-events-none' : '', 'flex flex-col items-center group text-gray-400']">
              <CameraIcon :class="[fullscreen != null ? fullscreen ? 'text-indigo-600' : '' : '', 'w-10 h-10 group-hover:text-gray-600 transition-all duration-300']" />
              <span :class="[fullscreen != null ? fullscreen ? 'text-indigo-600' : '' : '', 'font-semibold text-lg select-none uppercase group-hover:text-gray-600 transition-all duration-300']">Kamera</span>
            </div>
          </div>
          <p class="text-xs italic text-gray-600 select-none">Skaner działa tylko z urządzeniami z podczerwienią.</p>
          <div @click="$route.path !== '/dashboard/storage/scan' ? clearData('scanner') : ''" :class="[$route.path !== '/dashboard/storage/scan' && scannerDecode.length > 0 ? 'select-none rounded-md bg-indigo-200 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100 items-center text-center flex justify-center my-2' : '', 'transition-all duration-300']">
            <span v-if="$route.path !== '/dashboard/storage/scan' && scannerDecode.length > 0" class="absolute font-semibold text-lg">INNA PACZKA</span>
            <input v-if="fullscreen != null ? !fullscreen : false" @focus="focus = true" @blur="focus = false" type="text" v-model="scannerDecode" ref="scanner" name="scanner" id="scanner" style="caret-color: transparent;" class="opacity-0 block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
          </div>
        </div>

      </div>

      <div v-if="fullscreen">
        <!-- camera -->
        <div class="flex flex-col p-2 qr-container">
          <div :class="{ fullscreen: fullscreen }" ref="wrapper" @fullscreenchange="onFullscreenChange">
            <qrcode-stream @detect="onDecode" @init="onInit" ref="qrcodeStream">
              <div v-if="!error">
                <button v-if="!notification.hide" @click="fullscreen = !fullscreen" :class="[fullscreen ? 'rotate-180' : '', 'filter fullscreen-button bg-white rounded-lg hover:text-gray-400 transition-all duration-300 transform -scale-x-100']">
                  <ExternalLinkIcon class="w-6 h-6 bg-transparent" />
                </button>
              </div>

              <!-- fullscreen info about package -->
              <div v-if="fullscreen" class="absolute w-full bottom-0 left-0 p-4">
                <div class="flex justify-between">
                  <div class="flex items-center justify-center gap-1 font-bold select-none text-white hover:text-gray-400 transition-all duration-300">
                    <QrcodeIcon class="w-8 h-8" />
                    <div style="text-shadow: 4px 4px 5px rgba(0, 0, 0, 1);">
                      <h4 class="text-sm font-semibold">Identyfikator zamówienia</h4>
                      <p class="-mt-2 text-md">{{ currentPackageId(packageId) }}</p>
                    </div>
                  </div>
                  <div style="text-shadow: 4px 4px 5px rgba(0, 0, 0, 1);" class="self-center select-none text-white hover:text-gray-400 font-bold text-xl transition-all duration-300">
                    {{createUniquePartName(packageId)}}
                  </div>
                </div>
              </div>

              <!-- custom notification fullscreen -->
              <Transition name="fade">
              <div v-if="fullscreen && notification.show" :class="[notification.success ? 'bg-green-50' : 'bg-red-50', 'm-4 rounded-md p-4']">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <CheckCircleIcon :class="[notification.success ? 'text-green-400' : 'text-red-400', 'h-5 w-5']" aria-hidden="true" />
                  </div>
                  <div class="ml-3">
                    <h3 :class="[notification.success ? 'text-green-800' : 'text-red-800', 'text-sm font-medium']">{{ notification.head }}</h3>
                    <div :class="[notification.success ? ' text-green-700' : ' text-red-700', 'mt-1 text-sm']">
                      <p>{{ notification.subheader }}</p>
                    </div>
                  </div>
                  <div class="ml-auto pl-3">
                    <div class="-mx-1.5 -my-1.5">
                      <button @click.prevent="notification.show = false;notification.hide = false;" type="button" :class="[notification.success ? 'bg-green-50 text-green-500 hover:bg-green-100 focus:ring-green-600 focus:ring-offset-green-50' : 'bg-red-50 text-red-500 hover:bg-red-100 focus:ring-red-600 focus:ring-offset-red-50', 'inline-flex rounded-md  p-1.5  focus:outline-none focus:ring-2focus:ring-offset-2']">
                        <span class="sr-only">Dismiss</span>
                        <XIcon class="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              </Transition>

              <!-- redirect -->
              <div class="h-screen" v-if="fullscreen && redirect">
                <div class="right-0 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  <LoadingSpinnerRedirect />
                </div>
              </div>

            </qrcode-stream>
          </div>
        </div>
      </div>

    </div>
    <div v-if="step === 4">
      <SubtractStockPackage :imported="true" @close="reloadDataAfterScan" :packageData="packageData" :routeData="route" />
    </div>
  </div>
  <askForScan v-if="scanAsk" @accept="changeStep(packageData != null && !packageData.subtracted && !variantIdNotExists ? 4 : 3)" :buttonColor="`indigo`" :iconColor="`indigo`" :modalData="modalDataAccept" @closeModal="scanAsk = false" />
  <!-- <askForScan v-if="scanAsk" @accept="changeStep(3)" :buttonColor="`indigo`" :iconColor="`indigo`" :modalData="modalDataAccept" @closeModal="scanAsk = false" /> -->
</template>

<script>
import {db} from "@/firebase/gfbconf.js";
import moment from 'moment';
import axios from 'axios';
import { QrcodeStream } from 'vue3-qrcode-reader';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { TruckIcon, HomeIcon, CheckIcon, CameraIcon, ChevronDownIcon, CheckCircleIcon, ArrowCircleLeftIcon, EyeIcon, EyeOffIcon, QrcodeIcon, SwitchVerticalIcon, RefreshIcon  } from '@heroicons/vue/solid';
import { LogoutIcon, XIcon, ExternalLinkIcon, ExclamationIcon, ArrowLeftIcon, ArrowRightIcon } from '@heroicons/vue/outline';
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import askForScan from '@/components/AlertModal.vue';
import EmptyState from '../components/EmptyState.vue';
import LoadingSpinnerHub from '../components/LoadingSpinnerHub.vue';
import LoadingSpinnerRedirect from '../components/LoadingSpinnerRedirect.vue';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import SubtractStockPackage from './SubstractStockPackage.vue'
import { markRaw } from 'vue';
import randomstring from 'randomstring';

export default {
  components: {
    TruckIcon, LogoutIcon, XIcon, ExternalLinkIcon, LoadingSpinnerHub, LoadingSpinnerRedirect, QrcodeIcon, EmptyState, askForScan, EyeIcon, EyeOffIcon, ChevronDownIcon, CheckCircleIcon, ArrowCircleLeftIcon, Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions, Menu, MenuButton, MenuItem, MenuItems, QrcodeStream, HomeIcon, CheckIcon, CameraIcon, SwitchVerticalIcon, RefreshIcon, ExclamationIcon, ArrowLeftIcon, ArrowRightIcon, SubtractStockPackage
  },
  data () {
    return {
      scannerDecode: '',
      scanAsk: false,
      result: '',
      positions: [],
      cameraLoaded: false,
      orderPackages:'',
      testScannerId: '',
      routes: [],
      route: [],
      cameraLock: true,
      selected: '',
      step: 1,
      toggleInformation: {},
      orderChecked: {},
      loading: false,
      modalDataAccept: {
        headerText: "Skanowanie zamówienia",
        bodyText: "Czy na pewno chcesz zeskanować to zamówienie?",
        acceptButtonText: "Skanuj"
      },
      packageId: '',
      routeId: '',
      dbListener: null,
      statuses: [],
      open: true,
      moment: moment,
      error: false,
      errorLog: '',
      loading: true,
      modalData: {
        accept: false,
        headerText: '',
        bodyText: '',
      },
      fullscreen: null,
      notification: {
        show: false,
        head: "",
        subheader: "",
        success: false,
        hide: false,
      },
      resultDecode: null,
      isScanned: false,
      redirect: false,
      currentOrderIndex: 0,
      currentPackageIndex: 0,
      currentStage: 1,
      currentPackages: [],
      showAllOrders: false,
      variantIdNotExists: false,
      // selected: {
      //   customPartId: null,
      //   segment: null,
      //   warehouse: null,
      //   decoded: null,
      //   route: null,
      //   part: '',
      //   id: '',
      //   num: '',
      //   quantity: 1
      // },
      scene: null,
      allPackagesExists: true,
      packageData: null,
      defaultOptions: {
        backgroundColor: 0xfffffff,
        width: 640,
        fov: 30,
        border: 40,
        height: 640,
        //cameraPosition: {x: bin.height * 2, y: bin.height * 2, z: bin.height * 2},
        //cameranTarget: {x: bin.width/2, y: bin.height/2, z: bin.depth/2},
        highlightColor: 0xff0000
      },
      blockModal: {
        updatedBy: '',
        show: false
      },
      watchers: {
        error: null
      },
    }
  },
  beforeUnmount()
  {
    if(this.dbListener !== null){
        this.dbListener();
    }
  },
  methods: {
    test(order) {
        let confimationObject = {
          // confirmed: false,
          // skipped: false,
          status: 0,
          code: parseInt(randomstring.generate({
            length: 4,
            charset: 'numeric'
          })),
        };
      for(let i = 0; i < order.ref.packages.length; i++) {
        let packageData = order.ref.packages[i];
        packageData.scannedInStorage = true;
        packageData.subtracted = true;
      }
      db.collection('Orders').doc(order.ref.id).update({
        packages: order.ref.packages,
        confirmation: confimationObject,
        statusId: 7
      })
    },
    async reloadDataAfterScan() {
      await this.changeStep(2);
      this.clearData();
    },
    async refreshData() {
      this.blockModal.show = false;
      this.blockModal.updatedBy = '';
      await this.getRoutes();
      let found = false
      for(let i = 0; i < this.routes.length; i++) {
        if(this.routes[i].id === this.routeId) {
          found = true
          break;
        }
      }
      if(found) { 
        this.changeStep(2)
      } else {
        this.changeStep(1)
      }
    },
    currentProduct() {
      let productName = ''
      if(this.route.orders[this.currentOrderIndex].ref.packages[this.currentPackageIndex].variantId != null) {
        for(let i = 0; i < Object.keys(this.route.orders[this.currentOrderIndex].ref.positions).length; i++) {
          let position = Object.values(this.route.orders[this.currentOrderIndex].ref.positions)[i]
          if(position.product.variantId === this.route.orders[this.currentOrderIndex].ref.packages[this.currentPackageIndex].variantId) {
            productName = position.product.name
            break;
          }
        }
      }
      return productName
    },
    currentPackageId(id) {
      let str = id;
      let num = id.lastIndexOf(':')
      let result = str.slice(0, num)
      return result
    },
    setCurrentPackages() {
      this.currentPackages = [];
      let found = false
      this.currentStage = 0
      for(let i = 0; i < this.route.orders.length; i++) {
        for(let y = 0; y < this.route.orders[i].ref.packages.length; y++) {
          this.currentStage++
          this.currentPackages.push(this.route.orders[i].ref.packages[y]);
          if(i === this.currentOrderIndex && y === this.currentPackageIndex && !this.showAllOrders) {
            found = true
            break;
          }
        }
        if(found) {
          break;
        }
      }
    },
    countPackages() {
      let packages = 0;
      this.route.orders.forEach(order => {
        if(order.ref.packages == null || order.ref.packages.length === 0) {
          packages++
        } else {
          packages += order.ref.packages.length;
        }
      })
      return packages;
    },
    countScannedPackages() {
      let scannedPackages = 0;
      this.route.orders.forEach(order => {
        if(order.ref.packages != null) {
          for(let i=0; i<order.ref.packages.length; i++) {
            if(order.ref.packages[i].variantId == null) {
              this.variantIdNotExists = true;
            }
            if(order.ref.packages[i].scannedInStorage) {
              scannedPackages++
            }
          }
        }
      })
      return scannedPackages;
    },
    changeStage(direction) {
      if(direction === 'left') {
        if(this.currentPackageIndex > 0) {
          this.currentPackageIndex--;
          // this.openAdditionalInformation(0, true)
          this.setCurrentPackages()
          this.createPackages()
        }
        else if(this.currentOrderIndex > 0) {
          this.currentOrderIndex--;
          this.currentPackageIndex = this.route.orders[this.currentOrderIndex].ref.packages.length-1;
          // this.openAdditionalInformation(0, true)
          this.setCurrentPackages()
          this.createPackages()
        }
      }
      else if(direction === 'right') {
        if(this.currentPackageIndex < this.route.orders[this.currentOrderIndex].ref.packages.length-1) {
          this.currentPackageIndex++;
          // this.openAdditionalInformation(0, true)
          this.setCurrentPackages()
          this.createPackages()
        }
        else if(this.currentOrderIndex < this.route.orders.length - 1) {
          this.currentOrderIndex++;
          this.currentPackageIndex = 0;
          // this.openAdditionalInformation(0, true)
          this.setCurrentPackages()
          this.createPackages()
        }
      }

    },
    currentOrder(orders)
    {
      let preparedOrders = []
      if(this.showAllOrders) {
        preparedOrders = orders
      } else {
        preparedOrders = [orders[this.currentOrderIndex]]
      }
      return preparedOrders
    },
    checkOrders(index, order)
    {
      let allPackagesScanned = true
      if(order.packages !== undefined && order.packages !== null)
      {
        if(order.statusId !== 7)
        {
          for(let i=0; i<order.packages.length; i++)
          {
            if(!order.packages[i].scannedInStorage)
            {
              allPackagesScanned = false
              break;
            }
          }
        }
      }
      else
      {
        allPackagesScanned = false;
      }
      if(allPackagesScanned)
      {
        this.orderChecked[index].show = true;
      }
    },
    routeSelector(value)
    {
      this.routeId = value;
    },
    withoutPackage(index, id, packageData)
    {
      this.scanAsk = !this.scanAsk;
      this.packageId = id;
      this.packageData = packageData;
    },
    async changeStep(val)
    {
      switch(val)
      {
        case 1:
          await this.getRoutes();
          this.selected = '';
          this.$store.commit('setOrderIndex', null)
          this.cameraLock = false; 
          this.scanAsk = false;
          this.packageId = '';
          this.step = 1;
          break;
        case 2:
          for(let i = 0; i < this.routes.length; i++) {
            if(this.routes[i].id === this.routeId) {
              this.route = this.routes[i];
              break;
            }
          }
          await this.getSingleRoute(this.routeId);
          this.watchRoute();
          this.step = 2;
          if(this.allPackagesExists) {
            this.setCurrentPackages()
            this.$nextTick(() => {
              this.createScene();
              document.getElementById('previousStep').scrollIntoView({ behavior: 'smooth' });
            })
          }
          // if(this.fullscreen)
          // {
          //   this.fullscreen = false;
          //   this.redirect = false;
          // }
          break;
        case 3:
          this.step = 3
          break;
        case 4:
          this.step = 4
          break;
      }
    },
    async fixOrderPackages(order)
    {
      try
      {
        this.$store.commit('setNotification',{
          show: true,
          head: "Akcja zakończona sukcesem!",
          subheader: "Zamówienie zostało zaktualizowane.",
          success: true
        });
        await db.collection("Orders").doc(order.id).update({
          "statusId": 7
        })
        await this.getSingleRoute(this.routeId)
      }
      catch (error) {
        console.log(error);       
        this.$store.commit('setNotification',{
          show: true,
          head: "Akcja zakończona niepowodzeniem!",
          subheader: `${error.message}`,
          success: false
        });
      }
    },
    async getRoutes()
    {
      try
      {
        this.loading = true;
        const res = await axios.post(`${this.$store.state.apiLink}/storage/routes`)
        if(res.data.success)
        {
          this.routes = res.data.routes;
          this.loading = false;
          // this.$store.commit('setNotification',{
          //   show: true,
          //   head: "Akcja zakończona sukcesem!",
          //   subheader: `Pobrano: ${res.data.routes.length} ${this.changeNotificationSubheader('routes', res.data.routes.length)}`,
          //   success: true
          // });
        }
        else
        {
          this.routes = [];
          this.$store.commit('setNotification',{
            show: true,
            head: "Akcja zakończona niepowodzeniem!",
            subheader: res.data.log,
            success: false
          });
        }
      }
      catch (error)
      {
        console.log(error);
      }
    },
    changeNotificationSubheader(type, length)
    {
      let n = '';
      if(type === 'routes')
      {
        if(length === 1)
        {
          n = 'trasę.'
        }
        else if(length >= 2 && length <= 4)
        {
          n = "trasy."
        }
        else if(length >= 5)
        {
          n = 'tras.'
        }
      }
      else if(type === 'orders')
      {
        if(length === 1)
        {
          n = 'zamówienie.'
        }
        else if(length >= 2 && length <= 4)
        {
          n = "zamówienia."
        }
        else if(length >= 5)
        {
          n = 'zamówień.'
        }
      }
      return n;
    },
    async getSingleRoute(value)
    {
      try
      {
        this.route.orders = [];
        this.loading = true;
        const res = await axios.post(`${this.$store.state.apiLink}/storage/orders`, {
          packages: value,
        })
        if(res.data.success)
        {
          this.route.orders = res.data.orders
          for(let y=0; y<this.route.orders.length; y++)
          {
            this.toggleInformation[y] = {
              open: false,
            },
            this.orderChecked[y] = {
              show: false,
            }
          }
          // Check if all packages are created
          this.allPackagesExists = true;
          for(let i = 0; i < this.route.orders.length; i++) {
            if(this.route.orders[i].ref.packages == null) {
              this.currentPackageIndex = 0;
              this.allPackagesExists = false;
              this.showAllOrders = true;
              break;
            }
          }

          if(!this.route.loadingSequence) {
            this.currentPackageIndex = 0;
            this.allPackagesExists = false;
            this.showAllOrders = true;
          }

          if(this.route.vehicle.attribs.dimensions == null) {
            this.currentPackageIndex = 0;
            this.allPackagesExists = false;
            this.showAllOrders = true;
          }

          let foundDifferentStatus = false;
          for(let i = 0; i < this.route.orders.length; i++) {
            if(this.route.orders[i].ref.statusId !== 7) {
              this.currentOrderIndex = i;
              if(this.route.orders[i].ref.packages != null) {
                for(let y = 0; y < this.route.orders[i].ref.packages.length; y++) {
                  if(!this.route.orders[i].ref.packages[y].scannedInStorage) {
                    this.currentPackageIndex = y;
                    break;
                  }
                } 
              }
              foundDifferentStatus = true;
              break;
            }
          }

          if(!foundDifferentStatus) this.currentOrderIndex = this.route.orders.length - 1;
          this.loading = false;
          // this.$store.commit('setNotification',{
          //   show: true,
          //   head: "Akcja zakończona sukcesem!",
          //   subheader: `Pobrano: ${res.data.orders.length} ${this.changeNotificationSubheader('orders', res.data.orders.length)}`,
          //   success: true
          // });
        }
        else
        {
          this.route.orders = [];
          this.$store.commit('setNotification',{
            show: true,
            head: "Akcja zakończona niepowodzeniem!",
            subheader: res.data.log,
            success: false
          });
          this.loading = false
        }
      }
      catch (error)
      {
        console.log(error);  
          this.$store.commit('setNotification',{
            show: true,
            head: "Akcja zakończona niepowodzeniem!",
            subheader: error.message,
            success: false
          });
      }
    },
    watchRoute() {
      this.dbListener = db.collection("Routes").doc(this.routeId).onSnapshot((doc) => {
        if(doc.data() != null) {
          if(this.route.meta.lastModificationDate._seconds !== doc.data().meta.lastModificationDate.seconds) {
            this.blockModal.show = true;
            this.blockModal.updatedBy = doc.data().meta.name;
          }
        } else {
          this.blockModal.show = true;
          this.blockModal.updatedBy = 'Usunięto trase';
        }

      })
    },
    createPositionsList(ord)
    {
      let amount = 0;
      const positions = Object.entries(ord.positions).map((arr) => ({
        item: arr[1]
      }));
      for(let i=0; i<positions.length; i++)
      {
        amount += positions[i].item.product.amountOfPackages
      }
      return amount
    },
    createUniquePartName(id)
    {
      let str = id;
      let n = str.lastIndexOf(':');
      let result = str.substring(n + 1);

      return result;
    },
    openAdditionalInformation(indx, boolean)
    {
      // this.$store.commit('setOrderIndex', indx)
      if(this.toggleInformation[indx].open && !boolean)
      {
          this.toggleInformation[indx].open = false
      }
      else
      {
        for(let i = 0; i < Object.keys(this.toggleInformation).length; i++)
        {
            this.toggleInformation[i].open = false
        }
        this.toggleInformation[indx].open = true
      }
    },
    async onInit(promise)
    {
      try {
        await promise
      } catch(err)
      {
        this.error = true;
        if(err.name === 'NotAllowedError')
        {
          this.errorLog = "Musisz przyznać uprawnienia dostępu do kamery."
        }
        else if (err.name === 'NotFoundError')
        {
          this.errorLog = "Brak kamery w tym urządzeniu."
        }
        else if (err.name === 'NotSupportedError')
        {
          this.errorLog = "Wymagany bezpieczny kontekst (HTTPS, localhost)."
        }
        else if (err.name === 'NotReadableError')
        {
          this.errorLog = "Czy kamera jest już w użyciu?"
        }
        else if (err.name === 'OverconstrainedError')
        {
          this.errorLog = "Zainstalowane kamery są nieodpowiednie."
        }
        else if (err.name === 'StreamApiNotSupportedError')
        {
          this.errorLog = "Stream API nie jest obsługiwany w tej przeglądarce."
        }
        else if (err.name === 'InsecureContextError')
        {
          this.errorLog = 'Dostęp do kamery jest dozwolony tylko w bezpiecznym kontekście. Należy używać protokołu HTTPS.';
        }
        else
        {
          this.errorLog = `(${err.name})`;
        }
      }
    },
    async onDecode(value, fromScanner)
    {
      let isFound = true;
      try {
        let decodedResult = null
        if(fromScanner) {
          // this.packageId = value
          decodedResult = {content: value}
        } else {
          decodedResult = await value
        }
        if(decodedResult.content.length === 0)
        {
          isFound = false;
          throw 'ERROR: The length of the result is equal to 0;';
        }
        let preparedResult = decodedResult.content;
        
        let orderId = decodedResult.content.split(":")[0];
        if(orderId.includes("_")) {
          orderId = orderId.split("_")[1];
        }
        // this.getSegmentWherePartExists()
        if(!this.packageId.includes('_'))
        {
          preparedResult = preparedResult.split("_")[1];
        }
        const res = await axios.post(`${this.$store.state.apiLink}/storage/scan`, {
          result: preparedResult,
          id: orderId,
          packageId: this.packageId
        })
        if(res.data.success)
        {
          if(!this.fullscreen)
          {
            this.$store.commit('setNotification', {
              show: true,
              head: 'Akcja zakończona sukcesem!',
              subheader: res.data.scannedLog,
              success: true
            }); 
            setTimeout(async () => {
              await this.changeStep(2);
              this.clearData('scanner');
            }, 1000);
          }
          else
          {
            this.notification = {
              show: true,
              head: 'Akcja zakończona sukcesem!',
              subheader: res.data.scannedLog,
              success: true,
              hide: true,
            }
            this.redirect = false;
            setTimeout(async () => {
              await this.changeStep(2);
              this.clearData('camera');
            }, 1000);
          }
  
          let audio = new Audio('https://firebasestorage.googleapis.com/v0/b/jan-art.appspot.com/o/assets%2Fsuccess.mp3?alt=media&token=d15ba5f1-9521-4d55-b905-3c38499c3b27');
          audio.play();
        }
        else
        {
          throw res.data.log;
        }
      }
      catch (error) {
        if(isFound)
        {
          console.log(error);
          if(!this.fullscreen)
          {
            this.$store.commit('setNotification', {
              show: true,
              head: "Akcja zakończona niepowodzeniem!",
              subheader: error,
              success: false
            }); 
            this.clearData('scanner'); 
          }
          else
          {
            this.notification = {
              show: true,
              head: "Akcja zakończona niepowodzeniem!",
              subheader: error,
              success: false,
              hide: true,
            }
            setTimeout(() => {
              this.clearData();
            }, 3000);
          }
          let audio = new Audio('https://firebasestorage.googleapis.com/v0/b/jan-art.appspot.com/o/assets%2Ferror.mp3?alt=media&token=a1fabac7-7082-412f-9b64-25d54c0efe04');
          audio.play();
        }
      }
    },
    onFullscreenChange(event) {
      // This becomes important when the user doesn't use the button to exit
      // fullscreen but hits ESC on desktop, pushes a physical back button on
      // mobile etc.

      this.fullscreen = document.fullscreenElement != null
    },
    exitFullscreen()
    {
      if (!document.fullscreenElement) {
        return;
      }

      if (!document.hidden) {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.mozCancelFullScreen) {
          /* Firefox */
          document.mozCancelFullScreen()
        } else if (document.webkitExitFullscreen) {
          /* Chrome, Safari and Opera */
          document.webkitExitFullscreen()
        } else if (document.msExitFullscreen) {
          /* IE/Edge */
          document.msExitFullscreen()
        }
      }
    },
    async fixPackage(val)
    {
      try {
        let order = val.ref;
         order.packages = []
          const positions = Object.values(order.positions)
          for(let i=0; i<positions.length; i++)
          {
            const prod = (await db.collection("Products").doc(positions[i].product.id).get()).data();
            const parts = Object.values(prod.variants[positions[i].product.variantId].parts)
            for(let y=0;y<parts.length;y++)
            {
              order.packages.push({
                id: `${i+1}_${order.id}:${parts[y].name}`,
                subtracted: false,
                scanned: false,
                variantId: positions[i].product.variantId,
                scannedInStorage: false,
              })
            }
          }
          await db.collection("Orders").doc(order.id).update({
              "packages": order.packages,
          })
      } catch (error)
      {
        console.log(error);  
      }
    },
    requestFullscreen()
    {
      const elem = this.$refs.wrapper
      this.watchers.error = this.$watch('error', (newVal, oldVal) => {
        if(newVal) {
          this.error = true;
          this.fullscreen = false;
        }
      });
      while(elem != null)
      {
        if (elem.requestFullscreen) {
          elem.requestFullscreen()
        } else if (elem.mozRequestFullScreen) {
          /* Firefox */
          elem.mozRequestFullScreen()
        } else if (elem.webkitRequestFullscreen) {
          /* Chrome, Safari and Opera */
          elem.webkitRequestFullscreen()
        } else if (elem.msRequestFullscreen) {
          /* IE/Edge */
          elem.msRequestFullscreen()
        }
        elem = elem.parentElement;
      };
    },
    clearData(type)
    {
      if(type === 'scanner')
      {
        this.scannerDecode = '';
      }
      this.fullscreen = null;
      this.result = '';
      this.notification = {
        show: false,
        head: "",
        subheader: "",
        success: false,
        hide: false,
      };
      this.modalData = {
        accept: false,
        found: false,
        headerText: '',
        bodyText: '',
      };
    },
    getStatusById(id)
    {
      let currentStatus = {
          name: "Brak",
          info: "Brak",
          id: null
      }
      this.statuses.forEach((status) => {
          if(status.id === id){
              currentStatus = {
                  name: status.name,
                  info: status.info,
                  id: status.id
              }
          }
      })
      return currentStatus
    },
    async getStatuses()
    {
      let res = await db.collection("RouteStatuses").doc("StatusList").get()
      let resData = res.data()
      this.statuses = resData.list
    },
    createScene() {
      // Get container
      const container = document.getElementById("container")
      let width = container.clientWidth;
      let height = container.clientHeight;

      let mouse = new THREE.Vector2();
      let rect = container.getBoundingClientRect();
      this.scene = markRaw(new THREE.Scene()) ;

      // Create container
      let graphicsContainer = this.createBin()
      this.scene.add(graphicsContainer.helper)
      
      // Create packages
      this.createPackages()

      // init camera
      const cameraData = this.createCamera(width, height, graphicsContainer.bin)
      const camera = cameraData.camera

      // Add userData to scene
      this.scene.userData.element = container;
      this.scene.userData.camera = camera;
      
      // scene.userData.element.addEventListener('mousemove', onDocumentMouseMove, false);

      function onDocumentMouseMove (event) {
        event.preventDefault();
        mouse.x = ((rect.left + event.clientX) / width) * 2 - 1;
        mouse.y = ((rect.top -event.clientY) / height) * 2 + 1;
      }
    

      // Create renderer
      const renderer = new THREE.WebGLRenderer( {antialias: true, } );
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(width, height);

      // Create orbit controls
      this.scene.userData.controls = new OrbitControls(this.scene.userData.camera, renderer.domElement);
      this.scene.userData.controls.enablePan = false;
      this.scene.userData.controls.target = new THREE.Vector3(cameraData.position.center.x, cameraData.position.center.y, cameraData.position.center.z);

      // Set camera angle
      let angle;
      
      angle = Math.PI/4;
      this.scene.userData.controls.minAzimuthAngle = angle;
      this.scene.userData.controls.maxAzimuthAngle = angle;
      this.scene.userData.controls.update();
      
      this.scene.userData.controls.minAzimuthAngle = -Math.PI;
      this.scene.userData.controls.maxAzimuthAngle = Math.PI;
      this.scene.userData.controls.update();

      angle = Math.PI/3;
      this.scene.userData.controls.minPolarAngle = angle;
      this.scene.userData.controls.maxPolarAngle = angle;
      this.scene.userData.controls.update();
      this.scene.userData.controls.minPolarAngle = -Math.PI;
      this.scene.userData.controls.maxPolarAngle = Math.PI;

      // Render
      let animate = () => {
        this.renderScene(renderer, camera, graphicsContainer.bin)
        requestAnimationFrame(animate);
      };
      container.appendChild( renderer.domElement );
      animate()
    },
    createCamera(width, height, bin, camera) {
      if(camera === undefined) {
        camera = new THREE.PerspectiveCamera( this.defaultOptions.fov, width / height, 0.1, 100000 );
      }

      let distance = 0
      let size = {
        x: bin.width,
        y: bin.height,
        z: bin.depth,
        center: {
          x: bin.width/2,
          y: bin.height/2,
          z: bin.depth/2
        }
      }
      
      let minLength = Math.min(size.x, size.z);
      let maxLength = Math.max(size.x, size.z);
      let ratio = maxLength / minLength;
      if(ratio < 2) {
        if(size.x > size.z) {
          let diag = size.z * Math.sqrt(2);
          distance = this.fitObjToScreen(this.defaultOptions.fov, width/height, diag, this.defaultOptions.border)

          camera.position.x = size.center.x;
          camera.position.y = size.center.y + diag/2
          camera.position.z = size.center.z + size.y/2
          camera.lookAt(new THREE.Vector3(size.center.x, size.center.y, size.center.z))
          camera.translateZ(distance)
        } else {
          let diag = size.x * Math.sqrt(2);
          distance = this.fitObjToScreen(this.defaultOptions.fov, width/height, diag, this.defaultOptions.border)

          camera.position.x = -size.center.x + diag/2;
          camera.position.y = size.center.y;
          camera.position.z = size.center.z;
          camera.lookAt(new THREE.Vector3(size.center.x, size.center.y, size.center.z))
          camera.translateZ(distance)
        }
      } else {
        if(size.x > size.z) {
          distance = this.fitObjToScreen(this.defaultOptions.fov, width/height, size.x, this.defaultOptions.border)

          camera.position.x = size.center.x;
          camera.position.y = size.center.y + size.y/2
          camera.position.z = size.center.z + size.z/2
          camera.lookAt(new THREE.Vector3(size.center.x, size.center.y, size.center.z))
          camera.translateZ(distance)
        } else {
          distance = this.fitObjToScreen(this.defaultOptions.fov, width/height, size.z, this.defaultOptions.border)

          camera.position.x = -size.center.x + size.x/2;
          camera.position.y = size.center.y;
          camera.position.z = size.center.z;

          camera.lookAt(new THREE.Vector3(size.center.x, size.center.y, size.center.z))
          camera.translateZ(distance)
        }
      }
      let position = camera.position
      position.center = size.center
      return {camera, position}
    },
    fitObjToScreen(fov, aspect, objWidth, border) {
      let width = objWidth + (objWidth/100.0)*border*2;
      let distance = width/(2*aspect*Math.tan(((Math.PI*fov)/360)))
      return distance
    },
    renderScene(renderer, camera, bin) {
      const container = document.getElementById("container")

      if(container != null && this.scene != null) {
        const containerWidth = container.clientWidth;
        const containerHeight = container.clientHeight;
        const canvas = renderer.domElement;
        if(canvas.width !== containerWidth*window.devicePixelRatio || canvas.height !== containerHeight*window.devicePixelRatio) {
          renderer.setPixelRatio(window.devicePixelRatio);
          renderer.setSize(containerWidth, containerHeight);
          // renderer.setSize(containerWidth, containerHeight, false);
          this.createCamera(containerWidth, containerHeight, bin, this.scene.userData.camera)

          let angle;
          
          angle = Math.PI/4;
          this.scene.userData.controls.minAzimuthAngle = angle;
          this.scene.userData.controls.maxAzimuthAngle = angle;
          this.scene.userData.controls.update();
          
          this.scene.userData.controls.minAzimuthAngle = -Math.PI;
          this.scene.userData.controls.maxAzimuthAngle = Math.PI;
          this.scene.userData.controls.update();

          angle = Math.PI/3;
          this.scene.userData.controls.minPolarAngle = angle;
          this.scene.userData.controls.maxPolarAngle = angle;
          this.scene.userData.controls.update();
          this.scene.userData.controls.minPolarAngle = -Math.PI;
          this.scene.userData.controls.maxPolarAngle = Math.PI;
        }
        let rect = this.scene.userData.element.getBoundingClientRect();
        let width = rect.right - rect.left;
        let height = rect.bottom - rect.top;
        
        renderer.setViewport(0, 0, width, height);
        renderer.setScissor(0, 0, width, height);
        renderer.setClearColor(0xffffff);
        renderer.clear();

        if ( rect.bottom < 0 || rect.top  > renderer.domElement.clientHeight ||
              rect.right  < 0 || rect.left > renderer.domElement.clientWidth ) {
                return;
        }

        this.scene.userData.camera.aspect = width / height;
        this.scene.userData.camera.updateProjectionMatrix();
        this.scene.userData.controls.update();

        renderer.render( this.scene, camera );
      } else {
        while(this.scene != null && this.scene.children.length > 0){ 
          let object = this.scene.children[0];
            object.material.dispose();
            object.geometry.dispose();
            this.scene.remove(object); 
        }

        this.scene = null;
      }
    },
    createBin() {
      let bin = {
        x: 0,
        y: 0,
        z: 0,
        width: this.route.vehicle.attribs.dimensions == null ? 0 : this.route.vehicle.attribs.dimensions.width,
        height: this.route.vehicle.attribs.dimensions == null ? 0 : this.route.vehicle.attribs.dimensions.height,
        depth: this.route.vehicle.attribs.dimensions == null ? 0 : this.route.vehicle.attribs.dimensions.length,
        color: 0xff0000
      }
      let geometry = new THREE.BoxGeometry(
        bin.width,
        bin.height,
        bin.depth
      );

      let material = new THREE.MeshBasicMaterial({color: bin.color});
      let cube = new THREE.Mesh(geometry, material);
      let pos = {
        x: bin.x + bin.width/2.0,
        y: bin.y + bin.height/2.0,
        z: bin.z + bin.depth/2.0
      } 
      
      cube.position.x = pos.x;
      cube.position.y = pos.y;
      cube.position.z = pos.z;
      
      let helper = new THREE.BoxHelper(cube);
      helper.material.color.set(bin.color);

      // Add lines on one side to indicate where the box starts
      let lineMaterial = new THREE.LineBasicMaterial({ color: 0x000000 });
      let lineGeometry = new THREE.BufferGeometry().setFromPoints([
          new THREE.Vector3(bin.x, bin.y, bin.z + bin.depth),
          new THREE.Vector3(bin.x + bin.width, bin.y, bin.z + bin.depth),
          new THREE.Vector3(bin.x + bin.width, bin.y + bin.height, bin.z + bin.depth),
          new THREE.Vector3(bin.x, bin.y + bin.height, bin.z + bin.depth),
          new THREE.Vector3(bin.x, bin.y, bin.z + bin.depth)
      ]);
      let line = new THREE.Line(lineGeometry, lineMaterial);
      this.scene.add(line);

      return {helper, bin}
    },
    clearScene() {
      for(let i = this.scene.children.length - 1; i >= 0; i--){
          let object = this.scene.children[i];

          // Check if object is a mesh
          if(object instanceof THREE.Mesh){
              // remove object from scene
              this.scene.remove(object);

              // remove geometry
              if(object.geometry){
                  object.geometry.dispose();
                  object.geometry = undefined;
              }

              // remove material
              if(object.material){
                  // if material is an array
                  if(Array.isArray(object.material)){
                      object.material.forEach((material) => {
                          material.dispose();
                      });
                  }
                  else{
                      object.material.dispose();
                  }
                  object.material = undefined;
              }
          }
      }
    },
    createPackages() {
      let packages = []
      this.clearScene()
      
      for(let i = 0; i < this.currentStage; i++) {
        let pack = this.currentPackages[i];

        if(pack != null) {
          let preparedPackage = {
            x: pack.packageData.position.x,
            y: pack.packageData.position.y,
            z: pack.packageData.position.z,
            width: pack.packageData.dimensions.x,
            height: pack.packageData.dimensions.y,
            depth: pack.packageData.dimensions.z,
            color: pack.scannedInStorage ? 0x22c55e : this.currentStage === i+1 && !this.showAllOrders ? 0xfde047 : 0xd1d5db
          }

          let geometry = new THREE.BoxGeometry(
            preparedPackage.width,
            preparedPackage.height,
            preparedPackage.depth
          );
          let material = new THREE.MeshBasicMaterial({color: preparedPackage.color});
          let cube = new THREE.Mesh(geometry, material);

          let edges = new THREE.EdgesGeometry(cube.geometry);
          let line = new THREE.LineSegments(edges, new THREE.LineBasicMaterial({color: 0x000000}));
          cube.add(line)

          cube.position.x = preparedPackage.x + preparedPackage.width/2.0;
          cube.position.y = preparedPackage.y + preparedPackage.height/2.0;
          cube.position.z = preparedPackage.z + preparedPackage.depth/2.0;
          cube.name = 'box';

          packages.push(cube)
        }
      }
      packages.forEach((pack) => {
        this.scene.add(pack)
      })
    }
  },
  computed: {
    orderId() {
        return this.$route.params.id
    }
  },
  watch: {
    focus(isFocused)
    {
      if(!this.modalData.accept)
      {
        if(!isFocused)
        {
          this.$refs.scanner.focus();
        }
      }
    },
    scannerDecode(newVal)
    {
      if(newVal)
      {
        let input = this.$refs.scanner;
        if(newVal.length === 0)
        {
          input.readOnly = false;
        }
        else if(newVal.length > 1)
        {
          input.readOnly = true;
          // this.onDecodeScanner(newVal);
          this.onDecode(newVal, true)
        }
      }
    },
    fullscreen(enterFullscreen) {
      if(this.step === 3)
      {
        if(enterFullscreen)
        {
          this.requestFullscreen()
        }
        else
        {
          this.exitFullscreen();
          if(!this.fullscreen)
          {
            this.$nextTick(() => {
              this.$refs.scanner.focus(); 
            })
          }
        }
      }
    },
  },
  created()
  {
    moment.locale('pl')
    this.getRoutes();
    this.getStatuses();
  }
}
</script>
<style scoped>
.disableZoom {
  touch-action: manipulation;
}
#container {
  height: 55vh;
}
@media screen and (max-width: 767px) and (orientation:landscape) {
  #container {
    height: 80vh;
  }
}
@media (min-width: 768px) {
  #container {
    height: 60vh;
  }
}
.fullscreen {
  position: fixed;
  z-index: 1000;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.fullscreen-button {
  position: absolute;
  top: 0;
  right: 0;
  margin: 1rem;
}
.fullscreen-button img {
  width: 2rem;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.qr-container {
  background:
    linear-gradient(to right, grey 2px, transparent 2px) 0 0,
    linear-gradient(to right, grey 2px, transparent 2px) 0 100%,
    linear-gradient(to left, grey 2px, transparent 2px) 100% 0,
    linear-gradient(to left, grey 2px, transparent 2px) 100% 100%,
    linear-gradient(to bottom, grey 2px, transparent 2px) 0 0,
    linear-gradient(to bottom, grey 2px, transparent 2px) 100% 0,
    linear-gradient(to top, grey 2px, transparent 2px) 0 100%,
    linear-gradient(to top, grey 2px, transparent 2px) 100% 100%;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}
</style>