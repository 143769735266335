<template>
<div class="wrap flex items-center justify-center mt-4">
    <div class="loading">
        <div class="bounceball"></div>
        <div class="text ml-4 text-xl">Przekierowywanie..</div>
    </div>
</div>
</template>
<style lang="scss" scoped>

$width: 15px;
$height: 15px;
$bounce_height: 30px;

.text {
  color: #fff;
  display: inline-block;
  text-shadow: 4px 4px 5px rgba(0, 0, 0, 1);
}

.bounceball {
  position: relative;
  display: inline-block;
  height: 37px;
  width: $width;
  &:before {
    position: absolute;
    content: '';
    display: block;
    top: 0;
    width: $width;
    height: $height;
    border-radius: 50%;
    background-color: #fff;
    transform-origin: 50%;
    animation: bounce 500ms alternate infinite ease;
  }
}

@keyframes bounce {
  0% {
    top: $bounce_height;
    height: 5px;
    border-radius: 60px 60px 20px 20px;
    transform: scaleX(2);
  }
  35% {
    height: $height;
    border-radius: 50%;
    transform: scaleX(1);
  }
  100% {
    top: 0;
  }
}
</style>