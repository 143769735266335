<template>
  <nav v-if="!imported" class="hidden md:flex mb-4 h-8" aria-label="Breadcrumb">
      <ol role="list" class="flex space-x-4 rounded-md bg-white px-6 shadow">
          <li class="flex">
              <div class="flex items-center">
              <router-link to="/hub" class="transition ease-in-out duration-300 text-gray-400 hover:text-gray-500">
                  <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                  <span class="sr-only">Home</span>
              </router-link>
              </div>
          </li>
          <li class="flex">
              <div class="flex items-center">
              <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                  <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <router-link to="/dashboard/storage" class="transition ease-in-out duration-300 ml-4 text-xs font-medium text-gray-500 hover:text-gray-700" aria-current="page">Moduł magazyn</router-link>
              </div>
          </li>
          <li class="flex">
              <div class="flex items-center">
              <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                  <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <div class="ml-4 text-xs font-medium text-gray-400 cursor-default" aria-current="page">Skaner kodów QR</div>
              </div>
          </li>
      </ol>
  </nav>
  <router-link v-if="!imported" to="/dashboard/storage" class="block md:hidden transition ease-in-out duration-300 mb-2 text-sm font-medium text-blue-500 hover:text-blue-700" aria-current="page">
      &larr; Cofnij
  </router-link>
  <!-- <div class="text-red-500 text-3xl font-bold" v-if="imported">
    Wydawania paczki z magazynu!
  </div> -->
  <div :class="[!fullscreen ? 'flex flex-col justify-between gap-4 mt-10' : '', 'flex justify-center']">
    <!-- camera error alert. -->
    <div v-if="error" class="border-l-4 border-red-400 bg-red-50 p-4 mb-4">
      <div class="flex">
        <div class="flex items-center">
          <ExclamationIcon class="h-6 w-6 text-red-400" aria-hidden="true" />
        </div>
        <div class="ml-3">
          <p class="text-sm text-red-700">
            ERROR:
            {{ ' ' }}
            <a href="#" class="font-medium text-red-700 underline hover:text-red-600">{{errorLog.log}}</a>
          </p>
        </div>
      </div>
    </div>

    <div v-if="!fullscreen" class="flex flex-col justify-center items-center gap-4 mt-8">
      <span class="uppercase text-gray-500 font-bold text-2xl select-none text-center">Wybierz tryb do skanowania</span>
      <div class="flex gap-10">
        <div @click="fullscreen = false" class="flex flex-col items-center group text-gray-400">
          <QrcodeIcon :class="[fullscreen != null ? !fullscreen ? 'text-indigo-600' : '' : '', 'w-10 h-10 group-hover:text-gray-600 transition-all duration-300']" />
          <span :class="[fullscreen != null ? !fullscreen ? 'text-indigo-600' : '' : '', 'font-semibold text-lg select-none uppercase group-hover:text-gray-600 transition-all duration-300']">Skaner</span>
        </div>
        <div @click="error ? '' : fullscreen = true" :class="[error ? 'opacity-30 pointer-events-none' : '', 'flex flex-col items-center group text-gray-400']">
          <CameraIcon :class="[fullscreen != null ? fullscreen ? 'text-indigo-600' : '' : '', 'w-10 h-10 group-hover:text-gray-600 transition-all duration-300']" />
          <span :class="[fullscreen != null ? fullscreen ? 'text-indigo-600' : '' : '', 'font-semibold text-lg select-none uppercase group-hover:text-gray-600 transition-all duration-300']">Kamera</span>
        </div>
      </div>
      <p class="text-xs italic text-gray-600 select-none">Skaner działa tylko z urządzeniami z podczerwienią.</p>
      <div @click="$route.path !== '/dashboard/storage/scan' ? clearData('scanner') : ''" :class="[$route.path !== '/dashboard/storage/scan' && scannerDecode.length > 0 ? 'select-none rounded-md bg-indigo-200 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100 items-center text-center flex justify-center my-2' : '', 'transition-all duration-300']">
        <span v-if="$route.path !== '/dashboard/storage/scan' && scannerDecode.length > 0" class="absolute font-semibold text-lg">INNA PACZKA</span>
        <input v-if="fullscreen != null ? !fullscreen : false" @focus="focus = true" @blur="focus = false" type="text" v-model="scannerDecode" ref="scanner" name="scanner" id="scanner" style="caret-color: transparent;" class="opacity-0 block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
      </div>
    </div>

    <!-- camera -->
    <div v-if="fullscreen" class="flex flex-col p-2 qr-container">
      <div :class="{'pt-4': error}">
        <div :class="{ fullscreen: fullscreen }" ref="wrapper" @fullscreenchange="onFullscreenChange">
          <qrcode-stream @detect="onDecode" @init="onInit">

            <!-- <div v-if="false" class="bg-white select-none py-1 px-2 rounded-lg filter fullscreen-button-check relative flex items-start">
              <div class="flex h-6 items-center">
                <input v-model="haveQRCode" id="qr" aria-describedby="qr-code" name="qr" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 transition-all duration-300" />
              </div>
              <div class="ml-3 text-sm leading-6">
                <label for="qr" class="text-gray-900">
                  Mam <span class="font-semibold">QR magazynu</span> lub <span class="font-semibold"> segmentu</span>
                </label>
              </div>
            </div> -->

            <div v-if="!error">
              <button v-if="!notification.success" @click="fullscreen = !fullscreen" :class="[fullscreen ? 'rotate-180' : '','filter fullscreen-button bg-white rounded-lg hover:text-gray-400 transition-all duration-300 transform -scale-x-100']">
                <ExternalLinkIcon class="w-6 h-6 bg-transparent" />
              </button>
            </div>
            <div v-if="fullscreen">
              <!-- custom notification -->
              <Transition name="fade2">
                <div v-if="notification.show" :class="[fullscreen ? 'fixed inset-0 shadow-lg' : '']">
                  <div class="flex min-h-full items-end p-4">
                    <div :class="[notification.success ? 'bg-green-50' : 'bg-red-50', 'rounded-md p-4 w-full']">
                      <div class="flex">
                        <div class="flex-shrink-0">
                          <CheckCircleIcon :class="[notification.success ? 'text-green-400' : 'text-red-400', 'h-5 w-5']" aria-hidden="true" />
                        </div>
                        <div class="ml-3">
                          <h3 :class="[notification.success ? 'text-green-800' : 'text-red-800', 'text-sm font-medium']">{{ notification.head }}</h3>
                          <div :class="[notification.success ? ' text-green-700' : ' text-red-700', 'mt-1 text-sm']">
                            <p>{{ notification.subheader }}</p>
                          </div>
                        </div>
                        <div class="ml-auto pl-3">
                          <div class="-mx-1.5 -my-1.5">
                            <button @click.prevent="notification.show = false;" type="button" :class="[notification.success ? 'bg-green-50 text-green-500 hover:bg-green-100 focus:ring-green-600 focus:ring-offset-green-50' : 'bg-red-50 text-red-500 hover:bg-red-100 focus:ring-red-600 focus:ring-offset-red-50', 'inline-flex rounded-md  p-1.5  focus:outline-none focus:ring-2focus:ring-offset-2']">
                              <span class="sr-only">Dismiss</span>
                              <XIcon class="h-5 w-5" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition>
              <!-- 1st step fullscreen -->
              <Transition name="fade">
                <div class="relative p-5" v-if="modalData.found && step === 1">
                  <template enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                  </template>

                  <div class="fixed inset-0 z-50 w-screen overflow-y-auto shadow-lg">
                    <div class="flex min-h-full items-end justify-center p-4 text-center sm:p-0">
                      <div class="min-w-full sm:min-w-0 z-50 relative transform overflow-hidden rounded-lg bg-white shadow-md transition-all sm:my-8 sm:w-full sm:max-w-sm">
                        <div class="px-5 py-4 select-none" v-if="modalData.found">
                          <div class="font-semibold text-yellow-500 uppercase text-md flex items-center justify-center gap-2"><ExclamationIcon class="h-6 w-6 text-yellow-500" /> Wydawanie z magazynu!</div>
                          <div v-if="packageData" class="flex justify-center items-center text-center py-1 px-2 rounded mt-1 uppercase font-semibold text-md" :class="[selected.id.length === 0 ? 'text-red-500 bg-red-100' : 'text-green-500 bg-green-100']">
                            {{selected.id.length === 0 ? "Niezeskanowano paczki" : "Zeskanowano paczkę"}}
                          </div>
                          <div class="mt-3">
                            <p class="text-base uppercase font-semibold">
                              Paczka: 
                              <span v-if="modalData.headerText.length > 0">{{modalData.headerText}}</span>
                              <span v-if="modalData.headerText.length === 0">---</span>
                            </p>
                          </div>
                          <div class="font-semibold text-gray-600 uppercase py-2 text-sm opacity-60">{{modalData.bodyText}}</div>
                          <div class="flex justify-center">
                            <img v-if="modalData.found" class="h-36" :src="modalData.image.url" alt="">
                          </div>
                          <div class="mt-5 sm:mt-6 flex gap-2 select-none">
                            <button v-if="packageData == null" @click="modalData.accept = false;" type="button" :class="['transition-all duration-300 w-full flex justify-center items-center gap-x-2 rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus:ring-2 focus:ring-red-600 focus:ring-offset-2']">
                              <LogoutIcon class="transform -scale-x-100 -ml-0.5 h-5 w-5" aria-hidden="true" />
                              Anuluj
                            </button>
                            <button v-if="modalData.found && this.selected.id.length > 0" @click.prevent="changeStep(2)" type="button" :class="[packageData == null ? 'bg-indigo-600 hover:bg-indigo-500 focus:ring-indigo-600' : 'bg-green-500 hover:bg-green-400 focus:ring-green-500', 'transition-all duration-300 w-full flex justify-center items-center gap-x-2 rounded-md px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm focus:ring-2 focus:ring-offset-2 whitespace-nowrap']">
                              <CheckCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                              <span v-if="packageData == null">Przejdź dalej</span>
                              <span v-if="packageData != null">Wydaj z magazynu</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition>
              <!-- 2nd step fullscreen -->
              <Transition name="fade2">
                <div class="relative p-5" v-if="step === 2">
                  <template enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                  </template>

                  <div class="fixed inset-0 z-50 w-screen overflow-y-auto shadow-lg">
                    <div class="flex min-h-full items-end justify-center p-4 text-center sm:p-0">
                      <div class="min-w-full sm:min-w-0 z-50 relative transform overflow-hidden rounded-lg bg-white shadow-md transition-all sm:my-8 sm:w-full sm:max-w-sm">
                        <div class="select-none text-left">
                          <h2 class="text-sm font-medium text-gray-500 select-none">Wybór magazynu:</h2>
                            <ul role="list" :class="[storage.length > 2 ? 'sm:grid-cols-2 lg:grid-cols-4' : '', 'mt-3 grid grid-cols-1 gap-5 sm:gap-6']">
                              <li v-for="(strg, index) in storage" :key="index" class="col-span-1 flex rounded-md shadow-sm">
                                <div :class="['flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white']">
                                  <img class="shadow-md" :src="`https://api.dicebear.com/7.x/initials/svg?seed=${strg.name}&scale=50`">
                                </div>
                                <div class="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                                  <div class="flex-1 truncate px-4 py-2 text-sm">
                                    <a class="font-medium text-gray-900 hover:text-gray-600">{{strg.name}}</a>
                                  </div>
                                  <div class="flex-shrink-0 pr-4">
                                    <div class="flex h-6 items-center">
                                      <input v-if="!strg.blocked" v-model="strg.selected" @click="selectLocation($event, strg, 'warehouse')" id="storage" aria-describedby="storage-description" name="storage" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                            <div class="mt-5 sm:mt-6 flex gap-2 select-none">
                              <button @click.prevent="modalData.found = false;" type="button" :class="['transition-all duration-300 w-full flex justify-center items-center gap-x-2 rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus:ring-2 focus:ring-red-600 focus:ring-offset-2']">
                                Anuluj
                              </button>
                              <button :disabled="this.selected.warehouse === null" @click.prevent="changeStep(3)" type="button" :class="[this.selected.warehouse !== null ? '' : 'opacity-20', 'transition-all duration-300 w-full flex justify-center items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
                                <CheckCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                                Przejdź dalej
                              </button>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition>
              <!-- 3rd step fullscreen -->
              <Transition name="fade2">
                <div class="relative p-5" v-if="step === 3">
                  <template enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                  </template>

                  <div class="fixed inset-0 z-50 w-screen overflow-y-auto shadow-lg">
                    <div class="flex min-h-full items-end justify-center p-4 text-center sm:p-0">
                      <div class="min-w-full sm:min-w-0 z-50 relative transform overflow-hidden rounded-lg bg-white shadow-md transition-all sm:my-8 sm:w-full sm:max-w-sm">
                        <Transition name="fade">
                        <div class="pt-12 pb-14" v-if="segmentLoading">
                          <div class="wrap flex items-center justify-center mt-4">
                              <div class="loading">
                                  <div class="bounceball"></div>
                                  <div class="text ml-4">Pobieranie tras...</div>
                              </div>
                          </div>
                        </div>
                        </Transition>
                        <div class="px-5 py-4 select-none text-left">
                          <div v-if="!segmentLoading && segments.length > 0">
                            <h2 class="text-sm font-medium text-gray-500 select-none">Wybór segmentu:</h2>
                            <ul role="list" :class="[segments.length > 2 ? 'sm:grid-cols-2 lg:grid-cols-4' : '', 'mt-3 grid grid-cols-1 gap-5 sm:gap-6']">
                              <li v-for="(strg, index) in segments" :key="index" class="col-span-1 flex rounded-md shadow-sm">
                                <div :class="['flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white']">
                                  <img class="shadow-md" :src="`https://api.dicebear.com/7.x/initials/svg?seed=${strg.name}&scale=50`">
                                </div>
                                <div class="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                                  <div class="flex-1 truncate px-4 py-2 text-sm">
                                    <a class="font-medium text-gray-900 hover:text-gray-600">{{strg.name}}</a>
                                  </div>
                                  <div class="flex-shrink-0 pr-4">
                                    <div class="flex h-6 items-center">
                                      <input v-if="!strg.blocked" v-model="strg.selected" @click="selectLocation($event, strg, 'segment')" id="segments" aria-describedby="segments-description" name="segments" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <!-- <EmptyState v-if="!segmentLoading && segments.length === 0" /> -->
                          <div v-if="!segmentLoading && segments.length === 0" type="button" class="select-none relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                            <CubeTransparentIcon class="mx-auto h-12 w-12 text-gray-300" />
                            <span class="mt-2 block text-sm font-medium text-gray-400">Paczka nie została jeszcze zeskanowana...</span>
                          </div>
                          <div class="mt-5 sm:mt-6 flex gap-2 select-none">
                            <button @click.prevent="modalData.found = false;" type="button" :class="['transition-all duration-300 w-full flex justify-center items-center gap-x-2 rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus:ring-2 focus:ring-red-600 focus:ring-offset-2']">
                              Anuluj
                            </button>
                            <button :disabled="this.selected.segment === null" @click.prevent="changeStep(4)" type="button" :class="[this.selected.segment !== null ? '' : 'opacity-20', 'transition-all duration-300 w-full flex justify-center items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
                              <CheckCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                              Przejdź dalej
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition>
              <!-- 4th step fullscreen -->
              <Transition name="fade">
                <div class="relative p-5" v-if="step === 4">
                  <template enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                  </template>

                  <div class="fixed inset-0 z-50 w-screen overflow-y-auto shadow-lg">
                    <div class="flex min-h-full items-end justify-center p-4 text-center sm:p-0">
                      <div class="min-w-full sm:min-w-0 z-50 relative transform overflow-hidden rounded-lg bg-white shadow-md transition-all sm:my-8 sm:w-full sm:max-w-sm">
                        <Transition name="fade">
                        <div class="pt-12 pb-14" v-if="routeLoading">
                          <div class="wrap flex items-center justify-center mt-4">
                              <div class="loading">
                                <div class="bounceball"></div>
                                <div class="text ml-4">Pobieranie tras...</div>
                              </div>
                          </div>
                        </div>
                        </Transition>
                        <div class="px-5 py-4 select-none" v-if="!routeLoading">
                          <ul v-if="routes.length > 0" role="list" :class="[routes.length > 2 ? 'sm:grid-cols-2 lg:grid-cols-4' : '', 'mt-3 grid grid-cols-1 gap-5 sm:gap-6']">
                            <li v-for="(strg, index) in routes" :key="index" class="col-span-1 flex rounded-md shadow-sm">
                              <div :class="['flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white']">
                                <img class="shadow-md" :src="`https://api.dicebear.com/7.x/initials/svg?seed=${strg.title}&scale=50`">
                              </div>
                              <div class="flex flex-1 text-left items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                                <div class="flex-1 truncate px-4 py-2 text-sm">
                                  <a class="font-medium text-gray-900 hover:text-gray-600">{{strg.title}}</a>
                                </div>
                                <div class="flex-shrink-0 pr-4">
                                  <div class="flex h-6 items-center">
                                    <input v-if="!strg.blocked" v-model="strg.selected" @click="selectLocation($event, strg, 'route')" id="storage" aria-describedby="storage-description" name="storage" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                          <EmptyState v-if="routes.length === 0" />
                          <div class="mt-5 sm:mt-6 flex gap-2 select-none">
                            <button :disabled="this.selected.route === null" @click.prevent="changeStep(5)" type="button" :class="[this.selected.route !== null ? '' : 'opacity-20', 'transition-all duration-300 w-full flex justify-center items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
                              <CheckCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                              Przejdź dalej
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition>
            </div>

          </qrcode-stream>
        </div>
      </div>
    </div>
    <!-- <input placeholder="test" v-model="testScannerId" @change="test"  /> -->
    <div v-if="!fullscreen">
      <!-- 1st step !fullscreen -->
      <Transition name="fade">
        <div class="relative" v-if="modalData.found && step === 1">
          <template enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </template>

          <div class="flex min-h-full items-end justify-centertext-center">
            <div class="min-w-full sm:min-w-0 fullscreen-z relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-md transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
              <div class="px-5 py-4 select-none" v-if="modalData.found">
                <div class="font-semibold text-yellow-500 uppercase text-md flex items-center justify-center gap-2"><ExclamationIcon class="h-6 w-6 text-yellow-500" /> Wydawanie z magazynu!</div>
                <div v-if="packageData" class="flex justify-center items-center text-center py-1 px-2 rounded mt-1 uppercase font-semibold text-md" :class="[selected.id.length === 0 ? 'text-red-500 bg-red-100' : 'text-green-500 bg-green-100']">{{selected.id.length === 0 ? "Niezeskanowano paczki" : "Zeskanowano paczkę"}}</div>
                <div class="mt-3">
                  <p class="text-base uppercase font-semibold text-center">
                    Paczka: 
                    <span v-if="modalData.headerText.length > 0">{{modalData.headerText}}</span>
                    <span v-if="modalData.headerText.length === 0">---</span>
                  </p>
                </div>
                <div class="font-semibold text-gray-600 uppercase py-2 text-sm opacity-60 text-center">{{modalData.bodyText}}</div>
                <div class="flex justify-center">
                  <img v-if="modalData.found" class="h-36" :src="modalData.image.url" alt="">
                </div>
                <div class="mt-5 sm:mt-6 flex gap-2 select-none">
                  <button v-if="packageData == null" @click="modalData.accept = false;" type="button" :class="['transition-all duration-300 w-full flex justify-center items-center gap-x-2 rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus:ring-2 focus:ring-red-600 focus:ring-offset-2']">
                    <LogoutIcon class="transform -scale-x-100 -ml-0.5 h-5 w-5" aria-hidden="true" />
                    Anuluj
                  </button>
                  <button v-if="modalData.found && this.selected.id.length > 0" @click.prevent="changeStep(2)" type="button" :class="[packageData == null ? 'bg-indigo-600 hover:bg-indigo-500 focus:ring-indigo-600' : 'bg-green-500 hover:bg-green-400 focus:ring-green-500', 'transition-all duration-300 w-full flex justify-center items-center gap-x-2 rounded-md px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm focus:ring-2 focus:ring-offset-2 whitespace-nowrap']">
                    <CheckCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                    <span v-if="packageData == null">Przejdź dalej</span>
                    <span v-if="packageData != null">Wydaj z magazynu</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Transition>
      <!-- 2nd step !fullscreen -->
      <Transition name="fade2">
        <div class="relative" v-if="step === 2">
          <template enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </template>

          <div class="flex min-h-full items-end justify-centertext-center">
            <div class="min-w-full sm:min-w-0 fullscreen-z relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-md transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
              <div class="select-none text-left">
                <h2 class="text-sm font-medium text-gray-500 select-none">Wybór magazynu:</h2>
                  <ul role="list" :class="[storage.length > 2 ? 'sm:grid-cols-2 lg:grid-cols-4' : '', 'mt-3 grid grid-cols-1 gap-5 sm:gap-6']">
                    <li v-for="(strg, index) in storage" :key="index" class="col-span-1 flex rounded-md shadow-sm">
                      <div :class="['flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white']">
                        <img class="shadow-md" :src="`https://api.dicebear.com/7.x/initials/svg?seed=${strg.name}&scale=50`">
                      </div>
                      <div class="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                        <div class="flex-1 truncate px-4 py-2 text-sm">
                          <a class="font-medium text-gray-900 hover:text-gray-600">{{strg.name}}</a>
                        </div>
                        <div class="flex-shrink-0 pr-4">
                          <div class="flex h-6 items-center">
                            <input v-if="!strg.blocked" v-model="strg.selected" @click="selectLocation($event, strg, 'warehouse')" id="storage" aria-describedby="storage-description" name="storage" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div class="mt-5 sm:mt-6 flex gap-2 select-none">
                    <button @click.prevent="modalData.found = false;" type="button" :class="['transition-all duration-300 w-full flex justify-center items-center gap-x-2 rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus:ring-2 focus:ring-red-600 focus:ring-offset-2']">
                      Anuluj
                    </button>
                    <button :disabled="this.selected.warehouse === null" @click.prevent="changeStep(3)" type="button" :class="[this.selected.warehouse !== null ? '' : 'opacity-20', 'transition-all duration-300 w-full flex justify-center items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
                      <CheckCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                      Przejdź dalej
                    </button>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </Transition>
      <!-- 3rd step !fullscreen -->
      <Transition name="fade2">
        <div class="relative" v-if="step === 3">
          <template enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </template>

          <div class="flex min-h-full items-end justify-centertext-center">
            <div class="min-w-full sm:min-w-0 fullscreen-z relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-md transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
              <Transition name="fade">
              <div class="pt-12 pb-14" v-if="segmentLoading">
                <div class="wrap flex items-center justify-center mt-4">
                    <div class="loading">
                        <div class="bounceball"></div>
                        <div class="text ml-4">Pobieranie tras...</div>
                    </div>
                </div>
              </div>
              </Transition>
              <div class="select-none text-left">
                <div v-if="!segmentLoading && segments.length > 0">
                  <h2 class="text-sm font-medium text-gray-500 select-none">Wybór segmentu:</h2>
                  <ul role="list" :class="[segments.length > 2 ? 'sm:grid-cols-2 lg:grid-cols-4' : '', 'mt-3 grid grid-cols-1 gap-5 sm:gap-6']">
                    <li v-for="(strg, index) in segments" :key="index" class="col-span-1 flex rounded-md shadow-sm">
                      <div :class="['flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white']">
                        <img class="shadow-md" :src="`https://api.dicebear.com/7.x/initials/svg?seed=${strg.name}&scale=50`">
                      </div>
                      <div class="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                        <div class="flex-1 truncate px-4 py-2 text-sm">
                          <a class="font-medium text-gray-900 hover:text-gray-600">{{strg.name}}</a>
                        </div>
                        <div class="flex-shrink-0 pr-4">
                          <div class="flex h-6 items-center">
                            <input v-if="!strg.blocked" v-model="strg.selected" @click="selectLocation($event, strg, 'segment')" id="segments" aria-describedby="segments-description" name="segments" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <!-- <EmptyState v-if="!segmentLoading && segments.length === 0" /> -->
                <div v-if="!segmentLoading && segments.length === 0" type="button" class="select-none relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                  <CubeTransparentIcon class="mx-auto h-12 w-12 text-gray-300" />
                  <span class="mt-2 block text-sm font-medium text-gray-400">Paczka nie została jeszcze zeskanowana...</span>
                </div>
                <div class="mt-5 sm:mt-6 flex gap-2 select-none">
                  <button @click.prevent="modalData.found = false;" type="button" :class="['transition-all duration-300 w-full flex justify-center items-center gap-x-2 rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus:ring-2 focus:ring-red-600 focus:ring-offset-2']">
                    Anuluj
                  </button>
                  <button :disabled="this.selected.segment === null" @click.prevent="changeStep(4)" type="button" :class="[this.selected.segment !== null ? '' : 'opacity-20', 'transition-all duration-300 w-full flex justify-center items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
                    <CheckCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                    Przejdź dalej
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Transition>
      <!-- 4th step !fullscreen -->
      <Transition name="fade">
        <div class="relative" v-if="step === 4">
          <template enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </template>

          <div class="flex min-h-full items-end justify-centertext-center">
            <div class="min-w-full sm:min-w-0 fullscreen-z relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-md transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
              <Transition name="fade">
              <div class="pt-12 pb-14" v-if="routeLoading">
                <div class="wrap flex items-center justify-center mt-4">
                    <div class="loading">
                      <div class="bounceball"></div>
                      <div class="text ml-4">Pobieranie tras...</div>
                    </div>
                </div>
              </div>
              </Transition>
              <div class="select-none" v-if="!routeLoading">
                <ul v-if="routes.length > 0" role="list" :class="[routes.length > 2 ? 'sm:grid-cols-2 lg:grid-cols-4' : '', 'mt-3 grid grid-cols-1 gap-5 sm:gap-6']">
                  <li v-for="(strg, index) in routes" :key="index" class="col-span-1 flex rounded-md shadow-sm">
                    <div :class="['flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white']">
                      <img class="shadow-md" :src="`https://api.dicebear.com/7.x/initials/svg?seed=${strg.title}&scale=50`">
                    </div>
                    <div class="flex flex-1 text-left items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                      <div class="flex-1 truncate px-4 py-2 text-sm">
                        <a class="font-medium text-gray-900 hover:text-gray-600">{{strg.title}}</a>
                      </div>
                      <div class="flex-shrink-0 pr-4">
                        <div class="flex h-6 items-center">
                          <input v-if="!strg.blocked" v-model="strg.selected" @click="selectLocation($event, strg, 'route')" id="storage" aria-describedby="storage-description" name="storage" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
                <div v-if="routes.length === 0" type="button" class="select-none relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                  <CubeTransparentIcon class="mx-auto h-12 w-12 text-gray-300" />
                  <span class="mt-2 block text-sm font-medium text-gray-400">Produkt nie znajduje się w żadnej trasie...</span>
                </div>
                <div class="mt-5 sm:mt-6 flex gap-2 select-none">
                  <button :disabled="this.selected.route === null" @click.prevent="changeStep(5)" type="button" :class="[this.selected.route !== null ? '' : 'opacity-20', 'transition-all duration-300 w-full flex justify-center items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
                    <CheckCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                    Przejdź dalej
                  </button>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </Transition>
      <!-- 5th step !fullscreen -->
      <!-- <Transition name="fade">
        <div v-if="step === 5" class="rounded-md bg-green-50 p-4">
          <div class="flex">
            <div class="flex-shrink-0">
              <CheckCircleIcon class="h-5 w-5 text-green-400" aria-hidden="true" />
            </div>
            <div class="ml-3">
              <p class="text-sm font-medium text-green-800">Successfully uploaded</p>
            </div>
          </div>
        </div>
      </Transition> -->
    </div>

  </div>
</template>

<script>
import { db } from "@/firebase/gfbconf.js";
import { QrcodeStream } from 'vue3-qrcode-reader'
import moment from 'moment';
import axios from 'axios';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { CubeTransparentIcon, QuestionMarkCircleIcon, HomeIcon, CheckIcon, CameraIcon, ChevronDownIcon, CheckCircleIcon, ArrowCircleLeftIcon, EyeIcon, EyeOffIcon, QrcodeIcon, ExclamationIcon, XIcon, ExternalLinkIcon, ChevronDoubleRightIcon } from '@heroicons/vue/solid';
import { LogoutIcon } from '@heroicons/vue/outline';
import EmptyState from '../components/EmptyState.vue';
import LoadingSpinnerHub from '../components/LoadingSpinnerHub.vue';

export default {
  components: {
    CubeTransparentIcon, EmptyState, QuestionMarkCircleIcon, LogoutIcon, ExternalLinkIcon, XIcon, ExclamationIcon, QrcodeIcon, LoadingSpinnerHub, EyeIcon, EyeOffIcon, ChevronDownIcon, CheckCircleIcon, ArrowCircleLeftIcon, Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions, Menu, MenuButton, MenuItem, MenuItems, QrcodeStream, HomeIcon, CheckIcon, CameraIcon, ChevronDoubleRightIcon
  },
  data () {
    return {
      scannerDecode: '',
      haveQRCode: false,
      open: true,
      moment: moment,
      error: false,
      errorLog: {
        name: '',
        log: '',
      },
      loading: true,
      modalData: {
        accept: false,
        found: false,
        headerText: '',
        bodyText: '',
        product: null,
        num: null,
      },
      fullscreen: null,
      notification: {
        show: false,
        head: "",
        subheader: "",
        success: false,
      },
      resultDecode: null,
      isScanned: false,
      selectedPosition: "",
      step: 1,
      storage: [],
      singleSelect: false,
      selected: {
        customPartId: null,
        segment: null,
        warehouse: null,
        decoded: null,
        route: null,
        part: '',
        id: '',
        variantId: '',
        num: '',
        quantity: 1
      },
      segments: [],
      scanned: {
        warehouse: null,
        segment: null,
        package: '',
      },
      backlog: {},
      routes: [],
      routeLoading: false,
      segmentLoading: false,
      focus: false,
      watchers: {
        error: null,
      },
      testScannerId: '',
    }
  },
  props: ['imported', 'packageData', 'routeData'],
  methods: {
    test() {
      this.onDecode(this.testScannerId, true);
    },
    selectLocation(event, val, type)
    {
      if(type === 'warehouse')
      {
        val.selected = event.target.checked;
        if(val.selected)
        {
          this.selected.warehouse = val;
          for(let i=0; i<this.storage.length; i++)
          {
            if(!this.storage[i].selected)
            {
              this.storage[i].blocked = true;
            }
          }
        }
        else
        {
          for(let i=0; i<this.storage.length; i++)
          {
            this.storage[i].blocked = false;
          }
          this.selected.warehouse = null;
        }
      }
      else if(type === 'segment')
      {
        val.selected = event.target.checked;
        if(val.selected)
        {
          this.selected.segment = val;
          for(let i=0; i<this.segments.length; i++)
          {
            if(!this.segments[i].selected)
            {
              this.segments[i].blocked = true;
            }
          }
        }
        else
        {
          for(let i=0; i<this.segments.length; i++)
          {
            this.segments[i].blocked = false;
          }
          this.selected.segment = null;
        }
      }
      else if(type === 'route')
      {
        val.selected = event.target.checked;
        if(val.selected)
        {
          this.selected.route = val;
          for(let i=0; i<this.routes.length; i++)
          {
            if(!this.routes[i].selected)
            {
              this.routes[i].blocked = true;
            }
          }
        }
        else
        {
          for(let i=0; i<this.routes.length; i++)
          {
            this.routes[i].blocked = false;
          }
          this.selected.route = null;
        }
      }
    },
    async clearData(type)
    {
      if(type === 'camera')
      {
        this.scannerDecode = '';
        this.error = false;
      }
      else if(type === 'scanner')
      {
        this.error = false;
        this.scannerDecode = '';
        this.$refs.scanner.readOnly = false;
        this.$refs.scanner.focus();
      }
      this.modalData = {
        accept: false,
        found: false,
        headerText: '',
        bodyText: '',
        product: null,
        num: null,
      };
      this.selected = {
        customPartId: null,
        segment: null,
        warehouse: null,
        decoded: null,
        route: null,
        part: '',
        id: '',
        variantId: '',
        num: '',
        quantity: 1
      };
      this.scanned = {
        warehouse: null,
        segment: null,
        package: '',
      }
      this.segments = [];
      this.routes = [];
      this.scanned = {
        warehouse: null,
        segment: null,
        package: '',
      }
      this.step = 1;
      this.notification = {
        show: false,
        head: "",
        subheader: "",
        success: false,
      };
      await this.getAllStorages();
    },
    async checkForProducts()
    {
      try {
        this.routeLoading = true; 
        const res = await axios.post(`${this.$store.state.apiLink}/checkInRouteForProduct`, {
          product: this.selected,
        })
        if(res.data.success)
        {
          this.routes = res.data.routes;
        }
        else
        {
          this.routes = [];
        }
        this.routeLoading = false; 
      } 
      catch (error) {
        console.log(error);  
      }
    },
    async getSegmentWherePartExists()
    {
      try {
        this.segmentLoading = true;
        const product = (await db.collection('Products').doc(this.selected.id).get()).data();
        if(product.variants[this.selected.decoded])
        {
          let parts = Object.entries(product.variants[this.selected.decoded].parts);
          for(let i=0; i<parts.length; i++)
          {
            if(parts[i][0] === this.selected.num)
            {
              this.selected.part = parts[i][1];
              break;
            }
          }
        }
        if(this.selected.part == null) throw 'BRAK PACZEK.';
        
        const getSegment = await db.collection('Storage').doc(this.selected.warehouse.id.toString()).collection('Segments').where('productsId', 'array-contains-any', [this.selected.id]).orderBy("meta.created.unix", "desc").get()
        for(let i=0; i<getSegment.docs.length; i++)
        {
          let current = getSegment.docs[i].data();
          if(current.products[this.selected.id] != null)
          {
            const products = Object.entries(current.products[this.selected.id])
            for(let y=0; y<products.length; y++)
            {
              if(products[y][0].includes(this.selected.decoded))
              {
                if(`${this.selected.decoded}_${this.selected.part.name}` === products[y][0])
                {
                  if(this.selected.customPartId != null)
                  {
                    if(products[y][1].ids != null)
                    {
                      for(let x=0; x<products[y][1].ids.length; x++)
                      {
                        if(this.selected.customPartId === products[y][1].ids[x])
                        {
                          this.segments.push(current);
                          break;
                        }
                      }
                    }
                  }
                  else
                  {
                    this.segments.push(current);
                    break;
                  }
                }
              }
            }
          }
        }
        this.segmentLoading = false;
      }
      catch (error) {
        this.segmentLoading = false;
        console.log(error);
        if(this.fullscreen)
        {
          this.notification = {
            show: true,
            head: "Akcja zakończona niepowodzeniem!",
            subheader: 'Zamówienie nie zostało przydzielone do tej paczki.',
            success: false,
          };
          let audio = new Audio('https://firebasestorage.googleapis.com/v0/b/jan-art.appspot.com/o/assets%2Ferror.mp3?alt=media&token=a1fabac7-7082-412f-9b64-25d54c0efe04');
          audio.play();
          setTimeout(() => {
            this.notification.show = false;
          }, 3000);
          if(this.packageData == null) {
            setTimeout(() => {
              window.location.reload();
            }, 3500);
          } else {
            setTimeout(() => {
              this.$emit('close');
            }, 3500);
          }
        }
        else
        {
          let audio = new Audio('https://firebasestorage.googleapis.com/v0/b/jan-art.appspot.com/o/assets%2Ferror.mp3?alt=media&token=a1fabac7-7082-412f-9b64-25d54c0efe04');
          audio.play();
          this.$store.commit('setNotification',{
            show: true,
            head: "Akcja zakończona niepowodzeniem!",
            subheader: 'Zamówienie nie zostało przydzielone do tej paczki.',
            success: false
          });
          if(this.packageData == null) {
            setTimeout(() => {
              window.location.reload();
            }, 3500);
          } else {
            this.clearData('scanner');
            this.$emit('close');
          }
        }
      }
    },
    async removePackageFromStorage()
    {
      try {
        const res = await axios.post(`${this.$store.state.apiLink}/scan/substract`, {
          part: this.selected,
          position: this.selectedPosition,
          route: this.selected.route,
          user: this.$store.state.userData
        })
        if(res.data.success)
        {
          if(this.fullscreen)
          {
            this.notification = {
              show: true,
              head: "Akcja zakończona sukcesem!",
              subheader: res.data.log,
              success: true,
            };
          }
          else
          {
            this.$store.commit('setNotification',{
              show: true,
              head: "Akcja zakończona sukcesem!",
              subheader: 'Pomyślnie wydano paczkę z magazynu.',
              success: true
            });
          }
          let audio = new Audio('https://firebasestorage.googleapis.com/v0/b/jan-art.appspot.com/o/assets%2Fsuccess.mp3?alt=media&token=d15ba5f1-9521-4d55-b905-3c38499c3b27');
          audio.play();
          setTimeout(() => {
            this.notification.show = false;
          }, 3000);
          if(this.packageData == null) {
            setTimeout(() => {
              window.location.reload();
            }, 3500);
          } else {
            setTimeout(() => {
              this.$emit('close');
            }, 3500);
          }
        }
        else
        {
          throw res.data.log
        }
      }
      catch (error) {
        console.log(error);
        if(this.fullscreen)
        {
          this.notification = {
            show: true,
            head: "Akcja zakończona niepowodzeniem!",
            subheader: error,
            success: false,
          };
          let audio = new Audio('https://firebasestorage.googleapis.com/v0/b/jan-art.appspot.com/o/assets%2Ferror.mp3?alt=media&token=a1fabac7-7082-412f-9b64-25d54c0efe04');
          audio.play();
          setTimeout(() => {
            this.notification.show = false;
          }, 3000);
          if(this.packageData == null) {
            setTimeout(() => {
              window.location.reload();
            }, 3500);
          } else {
            setTimeout(() => {
              this.$emit('close');
            }, 3500);
          }
        }
        else
        {
          let audio = new Audio('https://firebasestorage.googleapis.com/v0/b/jan-art.appspot.com/o/assets%2Ferror.mp3?alt=media&token=a1fabac7-7082-412f-9b64-25d54c0efe04');
          audio.play();
          this.$store.commit('setNotification',{
            show: true,
            head: "Akcja zakończona niepowodzeniem!",
            subheader: error,
            success: false
          });
          if(this.packageData == null) {
            setTimeout(() => {
              window.location.reload();
            }, 3500);
          } else {
            this.clearData('scanner');
          }
        }
      }
    },
    async getAllStorages()
    {
      try {
        this.storage = [];
        const getStorage = await db.collection('Storage').orderBy("meta.created.unix", "desc").get()
        if(getStorage.empty) throw 'BRAK MAGAZYNÓW.'
        getStorage.forEach(doc => {
          let element = doc.data();
          element.selected = false;
          element.block = false;
          this.storage.push(element)
        });
      } catch (error) {
        console.log(error);
      }
    },
    async changeStep(val)
    {
      switch(val)
      {
        case 1:
          this.step = 1;
          break;
        case 2:
          if(this.packageData == null) {
            this.step = 2;
          }
          if(this.storage.length === 1) 
          {
            this.selected.warehouse = this.storage[0];
            this.changeStep(3);
          }
          else
          {
            this.step = 2;
          }
          break;
        case 3:
          if(this.packageData == null) {
            this.step = 3;
          }
          await this.getSegmentWherePartExists();
          if(this.packageData != null) {
            let productId = this.packageData.variantId.slice(this.packageData.variantId.indexOf('_p_')+3);
            let variantId = this.packageData.variantId
            let partId = variantId+'_'+this.packageData.id.slice(this.packageData.id.indexOf(':')+1);

            for(let i=0; i<this.segments.length; i++) {
              let segment = this.segments[i];
              for(let y = 0; y < Object.keys(segment.products[productId]).length; y++) {
                let part = Object.keys(segment.products[productId])[y];
                if(part === partId) {
                  let partData = segment.products[productId][partId]
                  if(partData.order != null && partData.order.length > 0)
                  {
                    for(let z = 0; z < partData.order.length; z++) {
                      let order = partData.order[z];
                      let preparedOrderId = this.packageData.id.slice(0, this.packageData.id.indexOf(':'))
                      let position = ""
                      if(preparedOrderId.includes("_")) {
                        position = preparedOrderId.split("_")[0];
                        preparedOrderId = preparedOrderId.split("_")[1];
                      }
                      if(order === preparedOrderId) {
                        this.selected.segment = segment;
                        this.selected.route = this.routeData;
                        this.selectedPosition = position;
                        this.changeStep(5);
                        break;
                      }
                    }
                  }
                }
              }
            }
          }
          break;
        case 4:
          this.step = 4;
          this.checkForProducts()
          break;
        case 5:
          this.step = 5;
          this.removePackageFromStorage(this.selected.warehouse);
      }
    },
    async onDecodeScanner(val)
    {
      try {
        let variantId = this.packageData != null ? this.packageData.variantId : null;
        let packageId = this.packageData != null ? this.packageData.id.slice(this.packageData.id.indexOf(':')+1) : null
        let preparedPackageId = null;

        if(packageId != null)
        {
          if(packageId.indexOf('-') === -1)
          {
            let num = /\d/;
            let nums = packageId.match(num);
            if(nums && nums.length > 0)
            {
              let indx = packageId.indexOf(nums[0]);
              let result = packageId.substring(indx, indx + nums[0].length);
              preparedPackageId = this.packageData != null ? packageId.indexOf(':') !== -1 ? packageId.slice(packageId.indexOf(':')+1) : packageId.indexOf('-') !== -1 ? packageId.slice(packageId.indexOf('-')+1) : result.trim() : null;
            }
          }
          else
          {
            preparedPackageId = this.packageData != null ? packageId.indexOf(':') !== -1 ? packageId.slice(packageId.indexOf(':')+1) : packageId.indexOf('-') !== -1 ? packageId.slice(packageId.indexOf('-')+1) : packageId : null;
          }
        }
        else
        {
          preparedPackageId = this.packageData != null ? packageId.indexOf(':') !== -1 ? packageId.slice(packageId.indexOf(':')+1) : packageId.indexOf('-') !== -1 ? packageId.slice(packageId.indexOf('-')+1) : packageId : null;
        }

        if(val.indexOf('-') > -1)
        {
          if(this.packageData != null && (variantId+'_'+preparedPackageId) !== val.slice(0, val.indexOf('-'))) throw "Product is not the same as the scanned one."
        }
        else 
        {
          if(this.packageData != null && (variantId+'_'+preparedPackageId) !== val) throw "Product is not the same as the scanned one."
        }
        
        let content = val
        let numOfPackage = null;
        let reslt = null;

        let n = content.lastIndexOf('-');
        if(n !== -1)
        {
          reslt = content.slice(0, n);
          let p = reslt.lastIndexOf('_');
          if(p === -1) throw 'ERROR; BRAK NUMERU PACZKI.'
          numOfPackage = reslt.substring(p+1);
        }
        else
        {
          let p = content.lastIndexOf('_');
          if(p === -1) throw 'ERROR; BRAK NUMERU PACZKI.'
          numOfPackage = content.substring(p+1);
        }

        // if(n === -1) throw 'ERROR;'
        // let numOfPackage = content.substring(n+1)

        const res = await axios.post(`${this.$store.state.apiLink}/scan/checkIfCorrect`, {
          id: content,
        })
        if(res.data.success)
        {
          let variantId = null;
          let lIndx = null;
          let productId = null;
          let customPartId = null;
          let customResult = null;
          let n = content.lastIndexOf('-');
          if(n !== -1)
          {
            //custom part id to indentity which is package scanned.
            customPartId = content.substring(n+1)
            customResult = content.slice(0, n);
            let l = customResult.lastIndexOf('_');

            // num of part
            numOfPackage = customResult.substring(l+1);

            // variant id
            variantId = customResult.slice(0, l);
            let num = variantId.lastIndexOf('_');

            //product id
            productId = variantId.substring(num+1)
          }
          else
          {
            // num of part
            let l = content.lastIndexOf('_');
            numOfPackage = content.substring(l+1);
            // variant id
            variantId = content.slice(0, l);
            let num = variantId.lastIndexOf('_');
            //product id
            productId = variantId.substring(num+1)
          };
          
          this.selected.id = productId;
          this.selected.variantId = variantId;
          this.selected.decoded = variantId;
          this.selected.num = numOfPackage;
          this.selected.customPartId = customPartId;
          this.modalData = {
            found: true,
            bodyText: res.data.variant.name,
            headerText: `${res.data.variant.num + '/' + res.data.variant.amountOfPackages}`,
            image: res.data.variant.image,
            product: res.data.product,
          };
        }
      }
      catch (error)
      {
        console.log(error);
        this.error = false;
        this.scannerDecode = '';
        this.$refs.scanner.readOnly = false;
        this.$refs.scanner.focus();
        this.$store.commit('setNotification',{
          show: true,
          head: "Akcja zakończona niepowodzeniem!",
          subheader: 'Produkt nie został znaleziony w bazie danych.',
          success: false
        });
      }
    },
    async onDecode(val, fromScanner)
    {
      try {
        let decodedResult = null
        if(fromScanner) {
          // this.packageId = value
          decodedResult = {content: val}
        } else {
          decodedResult = await val
        }
        if(decodedResult.content.length === 0) throw 'ERROR: The length of the result is equal to 0;';
        let variantId = this.packageData != null ? this.packageData.variantId : null;
        let packageId = this.packageData != null ? this.packageData.id.slice(this.packageData.id.indexOf(':')+1) : null;
        let preparedPackageId = null;

        if(packageId != null)
        {
          if(packageId.indexOf('-') === -1)
          {
            let num = /\d/;
            let nums = packageId.match(num);
            if(nums && nums.length > 0)
            {
              let indx = packageId.indexOf(nums[0]);
              let result = packageId.substring(indx, indx + nums[0].length);
              preparedPackageId = this.packageData != null ? packageId.indexOf(':') !== -1 ? packageId.slice(packageId.indexOf(':')+1) : packageId.indexOf('-') !== -1 ? packageId.slice(packageId.indexOf('-')+1) : result.trim() : null;
            };
          }
          else
          {
            preparedPackageId = this.packageData != null ? packageId.indexOf(':') !== -1 ? packageId.slice(packageId.indexOf(':')+1) : packageId.indexOf('-') !== -1 ? packageId.slice(packageId.indexOf('-')+1) : packageId : null;
          }
        }
        else
        {
          preparedPackageId = this.packageData != null ? packageId.indexOf(':') !== -1 ? packageId.slice(packageId.indexOf(':')+1) : packageId.indexOf('-') !== -1 ? packageId.slice(packageId.indexOf('-')+1) : packageId : null;
        }

        let content = decodedResult.content;
        
        if(content.indexOf('-') > -1)
        {
          if(this.packageData != null && (variantId+'_'+preparedPackageId) !== content.slice(0, content.indexOf('-'))) throw "Product is not the same as the scanned one."
        }
        else 
        {
          if(this.packageData != null && (variantId+'_'+preparedPackageId) !== content) throw "Product is not the same as the scanned one."
        }
        

        let numOfPackage = null;
        let reslt = null;

        let n = content.lastIndexOf('-');
        if(n !== -1)
        {
          reslt = content.slice(0, n);
          let p = reslt.lastIndexOf('_');
          if(p === -1) throw 'ERROR; BRAK NUMERU PACZKI.'
          numOfPackage = reslt.substring(p+1);
        }
        else
        {
          let p = content.lastIndexOf('_');
          if(p === -1) throw 'ERROR; BRAK NUMERU PACZKI.'
          numOfPackage = content.substring(p+1);
        }

        // if(n === -1) throw 'ERROR;'
        // let numOfPackage = content.substring(n+1)

        const res = await axios.post(`${this.$store.state.apiLink}/scan/checkIfCorrect`, {
          id: content,
        })
        if(res.data.success)
        {
          let variantId = null;
          let lIndx = null;
          let productId = null;
          let customPartId = null;
          let customResult = null;
          let n = content.lastIndexOf('-');
          if(n !== -1)
          {
            //custom part id to indentity which is package scanned.
            customPartId = content.substring(n+1)
            customResult = content.slice(0, n);
            let l = customResult.lastIndexOf('_');

            // num of part
            numOfPackage = customResult.substring(l+1);

            // variant id
            variantId = customResult.slice(0, l);
            let num = variantId.lastIndexOf('_');

            //product id
            productId = variantId.substring(num+1)
          }
          else
          {
            // num of part
            let l = content.lastIndexOf('_');
            numOfPackage = content.substring(l+1);
            // variant id
            variantId = content.slice(0, l);
            let num = variantId.lastIndexOf('_');
            //product id
            productId = variantId.substring(num+1)
          }
          this.selected.id = productId;
          this.selected.variantId = variantId;
          this.selected.decoded = variantId;
          this.selected.num = numOfPackage;
          this.selected.customPartId = customPartId;
          this.modalData = {
            found: true,
            bodyText: res.data.variant.name,
            headerText: `${res.data.variant.num + '/' + res.data.variant.amountOfPackages}`,
            image: res.data.variant.image,
            product: res.data.product,
          };
        }
      }
      catch (error)
      {
        console.log(error);
      }
    },
    async onInit (promise)
    {
      try {
        let res = await promise
      } catch(err)
      {
        this.error = true;
        if(err.name === 'NotAllowedError')
        {
          this.errorLog = {
            name: 'NotAllowedError',
            log: "Musisz przyznać uprawnienia dostępu do kamery.",
          }
        }
        else if (err.name === 'NotFoundError')
        {
          this.errorLog = {
            name: 'NotFoundError',
            log: "Brak kamery w tym urządzeniu."
          }
        }
        else if (err.name === 'NotSupportedError')
        {
          this.errorLog = {
            name: 'NotSupportedError',
            log: "Wymagany bezpieczny kontekst (HTTPS, localhost)."
          }
        }
        else if (err.name === 'NotReadableError')
        {
          this.errorLog = {
            name: 'NotReadableError',
            log: "Kamera jest zajęta."
          }
        }
        else if (err.name === 'OverconstrainedError')
        {
          this.errorLog = {
            name: 'OverconstrainedError',
            log: "Brak kamery spełniającej wymagania."
          }
        }
        else if (err.name === 'StreamApiNotSupportedError')
        {
          this.errorLog = {
            name: 'StreamApiNotSupportedError',
            log: "Stream API nie jest obsługiwany w tej przeglądarce."
          }
        }
        else if (err.name === 'InsecureContextError')
        {
          this.errorLog = {
            name: 'InsecureContextError',
            log: "Dostęp do kamery jest dozwolony tylko w bezpiecznym kontekście. Należy używać protokołu HTTPS."
          }
        }
        else
        {
          this.errorLog = {
            name: err.name,
            log: err.name,
          }
        }
      }
    },
    onFullscreenChange(event) {
      // This becomes important when the user doesn't use the button to exit
      // fullscreen but hits ESC on desktop, pushes a physical back button on
      // mobile etc.

      this.fullscreen = document.fullscreenElement != null
    },
    exitFullscreen()
    {
      if (!document.fullscreenElement) {
        return;
      }

      if (!document.hidden) {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.mozCancelFullScreen) {
          /* Firefox */
          document.mozCancelFullScreen()
        } else if (document.webkitExitFullscreen) {
          /* Chrome, Safari and Opera */
          document.webkitExitFullscreen()
        } else if (document.msExitFullscreen) {
          /* IE/Edge */
          document.msExitFullscreen()
        }
      }
    },
    requestFullscreen()
    {
      const elem = this.$refs.wrapper
      this.watchers.error = this.$watch('error', (newVal, oldVal) => {
        if(newVal) {
          this.error = true;
          this.fullscreen = false;
        }
      });
      while(elem != null)
      {
        if (elem.requestFullscreen) {
          elem.requestFullscreen()
        } else if (elem.mozRequestFullScreen) {
          /* Firefox */
          elem.mozRequestFullScreen()
        } else if (elem.webkitRequestFullscreen) {
          /* Chrome, Safari and Opera */
          elem.webkitRequestFullscreen()
        } else if (elem.msRequestFullscreen) {
          /* IE/Edge */
          elem.msRequestFullscreen()
        }
        elem = elem.parentElement;
      };
    },
  },
  watch: {
    focus(isFocused)
    {
      if(!this.modalData.accept)
      {
        if(!isFocused)
        {
          this.$refs.scanner.focus();
        }
      }
    },
    scannerDecode(newVal)
    {
      if(newVal)
      {
        let input = this.$refs.scanner;
        if(newVal.length === 0)
        {
          input.readOnly = false;
        }
        else if(newVal.length > 1)
        {
          input.readOnly = true;
          this.onDecodeScanner(newVal);
        }
      }
    },
    fullscreen(enterFullscreen) {
      if(enterFullscreen)
      {
        this.requestFullscreen()
      }
      else
      {
        this.exitFullscreen();
        this.$nextTick(() => {
          this.$refs.scanner.focus(); 
        })
      }
    },
  },
  async created()
  {
    moment.locale('pl')
    await this.getAllStorages();
    if(this.packageData != null) {
      // aNZhd_o_2361687861900962_pn_poduszki-3_v_88hrw4GWFIq6aQaK_p_OiHz62AmBIWq1KxoAXPV
      let productId = this.packageData.variantId.slice(this.packageData.variantId.indexOf('_p_')+3);
      let variantId = this.packageData.variantId
      const productData = (await db.collection('Products').doc(productId).get()).data();
      let variant = productData.variants[variantId];
      let modalData = {
        found: true,
        bodyText: variant.name,
        headerText: `${this.packageData.id}`,
        image: variant.image,
        product: productId,
      };
      this.modalData = modalData;
    }
  }
}
</script>
<style lang="scss" scoped>

$width: 15px;
$height: 15px;
$bounce_height: 30px;

.text {
  color: #aeafb1;
  display: inline-block;
}

.bounceball {
  position: relative;
  display: inline-block;
  height: 37px;
  width: $width;
  &:before {
    position: absolute;
    content: '';
    display: block;
    top: 0;
    width: $width;
    height: $height;
    border-radius: 50%;
    background-color: #aeafb1;
    transform-origin: 50%;
    animation: bounce 500ms alternate infinite ease;
  }
}

@keyframes bounce {
  0% {
    top: $bounce_height;
    height: 5px;
    border-radius: 60px 60px 20px 20px;
    transform: scaleX(2);
  }
  35% {
    height: $height;
    border-radius: 50%;
    transform: scaleX(1);
  }
  100% {
    top: 0;
  }
}

.qr-container {
  background:
    linear-gradient(to right, grey 2px, transparent 2px) 0 0,
    linear-gradient(to right, grey 2px, transparent 2px) 0 100%,
    linear-gradient(to left, grey 2px, transparent 2px) 100% 0,
    linear-gradient(to left, grey 2px, transparent 2px) 100% 100%,
    linear-gradient(to bottom, grey 2px, transparent 2px) 0 0,
    linear-gradient(to bottom, grey 2px, transparent 2px) 100% 0,
    linear-gradient(to top, grey 2px, transparent 2px) 0 100%,
    linear-gradient(to top, grey 2px, transparent 2px) 100% 100%;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

.fade-enter-active{
  transition: opacity 1s ease;
}
.fade-leave-active{
  transition: opacity 0s;
  display: none;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade2-enter-active,
.fade2-leave-active {
  transition: opacity 0.5s ease;
}

.fade2-enter-from,
.fade2-leave-to {
  opacity: 0;
}

.fullscreen {
  position: fixed;
  z-index: 1000;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.fullscreen-button{
  position: absolute;
  z-index: 2000;
  top: 0;
  right: 0;
  margin: 1rem;
}

.fullscreen-notification {
  position: absolute;
  z-index: 3000;
  top: 0;
  left: 0;
  margin: 1rem;
}

.fullscreen-button-check {
  position: absolute;
  z-index: 2000;
  top: 0;
  left: 0;
  margin: 1rem;
}

.fullscreen-button img {
  width: 2rem;
}

.scan-confirmation {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

</style>